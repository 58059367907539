import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';
import { IProduttore } from './produttori.service';

export interface IModelloDeviceMobile
{
  id : number;
  sigla : string;
  idCorp06004ProduttoreDeviceMobile : number;
  inizioValidita: Date;
  fineValidita: Date | undefined;
  
  produttore : IProduttore | null;
}

@Injectable({
  providedIn: 'root',
})
export class ModelliService extends BaseService<IModelloDeviceMobile> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/modelli-device-mobile', CountType.Yes, _httpClient);
  }
}


