export interface EnumDescriptor<T> {
    key: string;
    value: T;
    description: string;
}

/**
 * Restituisce un elenco di descrittori, uno per ciascuna voce dell'enum ricevuto in input. Ogni descrittore contiene
 * il nome dell'enum (key), il suo valore e la sua descrizione (una stringa pensata per essere visualizzata dall'utente).
 * La conversione da un enum alla rispettiva stringa di descrizione avviene nel metodo descriptionConverter ricevuto.
 * Questo metodo è utile per riempire un elenco (combo box) con tutti i possibili valori di un dato enum.
 * @param enumValue Oggetto enum di cui elencare le proprietà e per ciascuna restituire un descrittore.
 * @param enumType Tipologia di enum (può essere "number" o "string").
 * @param descriptionConverter Funzione (lambda) che converte un valore dell'enum nella stringa di descrizione.
 */
export function enumKeysToArray<T>(enumValue: any, enumType: "number" | "string",
    descriptionConverter: (input: T) => string = (input: T) => enumValue[input]): Array<EnumDescriptor<T>> {
    // Elenca i valori di un oggetto enum, esclude le proprietà non desiderate e mappa ciascun valore in un descrittore.
    return Object.entries(enumValue).filter(([key, value]) => typeof value === enumType)
        .map(([key, value]) => ({ key, value: value as T, description: descriptionConverter(value as T) }));
}