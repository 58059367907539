import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { IEtichettaTraduzione } from './etichetta-traduzione.service';

export interface IStatoModello {
  id: number;
  idCorp03005EtichettaTraduzione: number;

  etichettaTraduzione?: IEtichettaTraduzione;
}
@Injectable({
  providedIn: 'root'
})
export class StatiModelliService extends BaseService<IStatoModello> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/stati-modelli', CountType.Yes, _httpClient);
  }
}
