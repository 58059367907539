<div class="layout-wrapper layout-static" [ngClass]="containerClass">
  <app-topbar (showSidebar)="showSidebar = $event"></app-topbar>
  <div class="layout-sidebar">
      <app-sidebar [show] = "showSidebar"></app-sidebar>
  </div>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
