import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; 
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslocoInitService {

  constructor(@Inject(TranslateService) private translateService: TranslateService) {}

  loadTranslations(lang: string = 'it'): Subscription {
    this.translateService.setDefaultLang(lang);
    return this.translateService.reloadLang(lang).subscribe(_ => {}); 
  }
}