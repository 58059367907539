<p-sidebar
  [(visible)]="sidebarVisible"
  appendTo="body"
  [baseZIndex]="10000"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="p-sidebar-lg"
  (onHide)="clearForm()"
>
  <form [formGroup]="formGroup">
    <h2 class="p-sidebar-header-text">{{ formTitle }}</h2>
    <div class="formgrid grid">
      <div *ngFor="let formItem of formItemComponents" class="field" [class]="formItem.widthClass">
        <label for="{{ formItem.key }}">{{ formItem.label }}<span *ngIf="formItemIsRequired(formItem.key)"> *</span></label>
        <input
          *ngIf="formItem.type == 'text'"
          id="{{ formItem.key }}"
          formControlName="{{ formItem.formControlName }}"
          type="text"
          class="p-inputtext text-base text-color surface-overlay p-2 border-1 border-solid  border-round appearance-none outline-none focus:border-primary w-full"
          placeholder="{{ formItem.label }}"
          pInputText
        />
        <p-calendar
          *ngIf="formItem.type == 'date'"
          formControlName="{{ formItem.formControlName }}"
          panelStyleClass="custom-calendar-panel"
          id="{{ formItem.key }}"
          placeholder="{{ formItem.label }}"
          dateFormat="{{ formItem.dateFormat }}"
          inputStyleClass="p-calendar"
          [style]="{ width: '100%' }"
          [maxDate]="formItem.maxDate"
          [minDate]="formItem.minDate"
          [showButtonBar]="true"
          readonlyInput = true
          ></p-calendar>
        <p-multiSelect
          *ngIf="formItem.type === 'multiSelect'"
          [id]="formItem.key.toString()"
          [options]="formItem.dropdownOptions | orderBy: formItem.dropdownOptionLabel"
          [optionLabel]="formItem.dropdownOptionLabel"
          [optionValue]="formItem.dropdownOptionValue"
          [formControlName]="formItem.formControlName"
          [placeholder]="formItem.label"
          [style]="{ width: '100%' }"
        />
      <p-dropdown
          labelKey="formItem.label"
          valuekey="formItem.key"
          *ngIf="formItem.type === 'dropdown'"
          [id]="formItem.key.toString()"
          [options]="formItem.dropdownOptions | orderBy: formItem.dropdownOptionLabel"
          [optionLabel]="formItem.dropdownOptionLabel"
          [optionValue]="formItem.dropdownOptionValue"
          [placeholder]="formItem.label"
          [formControlName]="formItem.formControlName"
          [style]="{ width: '100%' }"
        >
        <ng-template let-selectedItem pTemplate="selectedItem">
          {{ selectedItem | splitAndTransloco: formItem.dropdownOptionLabel }}
        </ng-template>
          <ng-template let-dropdownitem pTemplate="item">
            {{ dropdownitem | splitAndTransloco: formItem.dropdownOptionLabel }}
          </ng-template>
        </p-dropdown>

        <p-fileUpload
          #fileUpload
          *ngIf="formItem.type === 'upload'"
          mode="advanced"
          chooseLabel="{{ fileSelected || getUploadedFile(formItem.key) ? ('Cambia' | transloco) : ('Scegli' | transloco) }}"
          cancelLabel="{{ 'Cancella' | transloco }}"
          id="{{ formItem.key }}"
          accept=".png"
          [showUploadButton]="false"
          [customUpload]="true"
          (onSelect)="onSelect($event, formItem.key)"
          (onClear)="fileSelected = null"
          [maxFileSize]="1000000"
        >
          <ng-template let-file pTemplate="file">
            <div *ngIf="fileSelected">{{ fileSelected.name }}</div>
          </ng-template>
          <ng-template pTemplate="content">
            <div *ngIf="!fileSelected">
              {{ getUploadedFile(formItem.key) }}
            </div>
          </ng-template>
        </p-fileUpload>

        <p-inputNumber
          class="w-100"
          *ngIf="formItem.type === 'number'"
          id="{{ formItem.key }}"
          formControlName="{{ formItem.formControlName }}"
          [useGrouping]="false"
          [showClear]="true"
          [showButtons]="true"
          styleClass="w-100"
        />
        <div *ngIf="formItemHasValidationError(formItem.key)" class="text-red-500 text-sm">
          {{ getFormItemValidationErrorMessage(formItem.key) }}
        </div>
      </div>
    </div>

    <div class="flex justify-content-end">
      <p-button
        type="submit"
        icon="pi pi-save"
        label="{{ 'Salva' | transloco  }}"
        (click)="onClickSave()"
        styleClass="button-18"
        [disabled]="!formGroup.valid"
      ></p-button>
      <p-button
        type="button"
        icon="pi pi-times"
        label="{{ 'Annulla' | transloco }}"
        (click)="hide()"
        styleClass="button-18"
      ></p-button>
    </div>
  </form>
</p-sidebar>
