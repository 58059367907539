import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { SidebarMultiItemComponent } from './sidebar-multi-item/sidebar-multi-item.component';
import { DropdownModule } from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    animations: [
        trigger('animation', [
            transition(':enter', [
                animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate('200ms', style({ transform: 'translateX(-100%)', opacity: 1 }))
            ])
        ]),
    ],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
    imports: [CommonModule, SidebarItemComponent, SidebarMultiItemComponent, DropdownModule, RouterModule, TranslocoModule]
})
export class SidebarComponent {
  @Input() show = false;
}
