import { Injectable } from '@angular/core';
import { ICentro } from './centri.service';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ISistemaAnalisi {
  id: number;
  sigla: string;
  barcode: string;
  idCorp01004Centro : number;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  centro: ICentro | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class SistemiAnalisiService extends BaseService<ISistemaAnalisi> {
  constructor(_httpClient: HttpClient) {
    super("service-db-api/sistemi-analisi", CountType.Yes, _httpClient);
  }
}
