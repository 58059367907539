import { Component } from '@angular/core';
import { SidebarItemComponent } from '../sidebar-item/sidebar-item.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-sidebar-multi-item',
  standalone: true,
  imports: [SidebarItemComponent],
  animations: [
    trigger("anim", [
      state(
        "collapsed",
        style({
          height: "0",
        })
      ),
      state(
        "expanded",
        style({
          height: "*",
        })
      ),
      transition("collapsed <=> expanded", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
    ]),
  ],
  templateUrl: './sidebar-multi-item.component.html',
  styleUrl: './sidebar-multi-item.component.scss'
})
export class SidebarMultiItemComponent {
}
