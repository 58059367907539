import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';

export interface ILingua {
  id: number;
  sigla: string;
}

@Injectable({
  providedIn: 'root'
})
export class LingueService extends BaseService<ILingua> {

  constructor(_httpClient: HttpClient) {
    super("service-db-api/lingue", CountType.No, _httpClient);
  }
}
