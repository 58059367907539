import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
@Pipe({
  name: 'splitAndTransloco',
  standalone: true
})
export class SplitPipe implements PipeTransform {
	constructor(private translocoService: TranslocoService) {}

  transform(options: any, params: string): string[] {
    const translationKeys = params.split('.');
		let keyToTranslate = options;
    for (const key of translationKeys) {
      keyToTranslate = keyToTranslate[key];
    }
    return  this.translocoService.translate(keyToTranslate);
  }

}
