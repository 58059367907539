import { Component, ViewChild, model } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { CfmPageHeaderComponent } from '../../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../../components/cfm-table-col/cfm-table-col.component';
import { DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from '../../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { IModelloDeviceMobile, ModelliService } from '../../../../services/modelli-device-mobili.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { IProduttore, ProduttoriService } from '../../../../services/produttori.service';
import { Observable } from 'rxjs';
import { IFindResult } from '../../../../services/base.service';
import { CfmMessageService } from '../../../../services/cfm-message.service';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-modelli',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    ButtonModule,
    CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
    TranslocoModule,
    MenuModule
  ],
  templateUrl: './modelli.component.html',
  styleUrl: './modelli.component.scss'
})
export class ModelliComponent {
  @ViewChild('tableModelli') public tableModelli!: CfmTableComponent<IModelloDeviceMobile>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IModelloDeviceMobile>;

  public modelli: IModelloDeviceMobile[] = [];
  public produttori: IProduttore[] = [];
  public items: MenuItem[] = [];
  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IModelloDeviceMobile, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoModello';
    else return 'ModificaModello';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  constructor(private _translocoService: TranslocoService,private _modelloService: ModelliService,private _produttoriService: ProduttoriService,  private _cfmMessageService:CfmMessageService) {
    this.formValidators.set('idCorp06004ProduttoreDeviceMobile', [Validators.required]);
    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('inizioValidita', [Validators.required]);

    this.loadProduttori().subscribe(result => this.produttori = result.data);
  }

  openMenu(menu: Menu, event: MouseEvent, modello: IModelloDeviceMobile) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(modello)              
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(modello),
                  command: () => this.onClickDelete(modello.id)
                }
            ]
        }
    ];
  }

  public loadModelli(event?: OnLoadItemsEvent<IModelloDeviceMobile>): void {
    const relatedEntities: Array<keyof IModelloDeviceMobile> = ['produttore'];
    this._modelloService.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
      this.modelli = result.data;
      this.totalCount = result.count;
    });
  }

  public loadProduttori(): Observable<IFindResult<IProduttore>> {
    return this._produttoriService.find();
  }
  
  public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
    this.isFineValiditaDisabled;
  }

  public onFormSave(modello: IModelloDeviceMobile): void {
    if (this.formMode == null) return;
    this.formMode == 'add' ? this.insertModello(modello) : this.updateModello(modello);
  }

  private insertModello(modello: IModelloDeviceMobile): void {
    this._modelloService.insert(modello).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableModelli.loadItems();
    });
  }

  private updateModello(modello: IModelloDeviceMobile): void {
    const modelloCopy: IModelloDeviceMobile = { ...modello };
    modelloCopy.produttore = null;
    this._modelloService.update(modello.id, modelloCopy).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableModelli.loadItems();
    });
  }

  public isDeleteDisabled(modello: IModelloDeviceMobile) {
    if (modello.inizioValidita < new Date() && modello.fineValidita === null) return false;
    
    return modello.inizioValidita > new Date() || (modello.fineValidita !== undefined && modello.fineValidita < new Date());
  }

  public deleteModello(id:number){
    this._modelloService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneModelloDeviceAvvenuta'));
      this.tableModelli.loadItems();
    });
  }

  public onClickEdit(modello: IModelloDeviceMobile): void {
    this.formMode = 'edit';
    this.newEditSidebarForm.showOnEditMode(modello);
    this.isFineValiditaDisabled;
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaCancellazioneModelloDevice'),
        accept: () => this.deleteModello(id),
      }
    );
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IModelloDeviceMobile>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
