import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CfmMessageService } from './cfm-message.service';
import { TranslocoService } from '@ngneat/transloco';

export interface IVersion {
  microservizio: string;
  versione: string;
  data: string;
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private http: HttpClient, private _cfmMessageService:CfmMessageService, private _translocoService: TranslocoService) {}

  getVersions(): Observable<IVersion[]> {
    const endpoints = [
      `${environment.baseApi}/health/version`,
      `${environment.baseApi}/audit-log-api/health/version`,
      `${environment.baseApi}/auth-api/health/version`,
      `${environment.baseApi}/service-db-api/health/version`,
    ];

    return forkJoin(
      endpoints.map((endpoint) =>
        this.http.get<IVersion>(endpoint).pipe(
          // In caso di errore...
          catchError(error => {
            this._cfmMessageService.showErrorMessage(this._translocoService.translate<string>('ErroreInGetVersionService') + endpoint.split('/')[3],)
            console.error(`Error fetching version from ${endpoint}`, error);
            return of({
              microservizio: endpoint.split('/')[3],
              versione: 'unknown',
              data: '0000-00-00T00:00:00Z'
            } as IVersion);
          })
        )
      )
    );
  }
}
