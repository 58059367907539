import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ToastModule } from 'primeng/toast';
import { LingueService } from './services/lingue.service';
import { Subject } from 'rxjs';
import { SpinnerLoadingComponent } from "./pages/layout/spinner-loading/spinner-loading.component";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, ToastModule, SpinnerLoadingComponent, ConfirmDialogModule, CommonModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  public readonly avaiableLangsLoaded$: Subject<void> = new Subject<void>();

  constructor(
    private _lingueService: LingueService,
    private _translocoService: TranslocoService
  ) {}
    
  ngOnInit(): void {
    // All'ingresso sull'applicazione vengono recuperate le lingue disponibili
    this._lingueService.find().subscribe(lingue => {
      this._translocoService.setAvailableLangs(lingue.data.map(l => l.sigla));
      this.avaiableLangsLoaded$.next();
    });
  }
}