import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BaseService, CountType } from './base.service';

export interface ISistemaInformativo {
  sigla: string;
}

@Injectable({
  providedIn: 'root',
})
export default class SistemiInformativiService extends BaseService<ISistemaInformativo> {

  constructor(_httpClient: HttpClient) {
    super("service-db-api/sistemi-informativi", CountType.No, _httpClient);
  }

  public getByNomeUtente(nomeUtente: string): Observable<ISistemaInformativo> {
    return this._httpClient.get<ISistemaInformativo>(
      `${environment.baseApi}/service-db-api/utenti/${nomeUtente}/sistemi-informativi`
    );
  }
}
