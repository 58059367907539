import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { permissionGuard } from './guards/permission.guard';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoggedLayoutComponent } from './pages/layout/logged-layout/logged-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AziendeComponent } from './pages/anagrafiche/aziende/aziende.component';
import { NazioniComponent } from './pages/anagrafiche/nazioni/nazioni.component';
import { StabilimentiComponent } from './pages/anagrafiche/stabilimenti/stabilimenti.component';
import { DeviceComponent } from './pages/anagrafiche/device/device/device.component';
import { ModelliComponent } from './pages/anagrafiche/device/modelli/modelli.component';
import { ProduttoriComponent } from './pages/anagrafiche/device/produttori/produttori.component';
import { UtentiComponent } from './pages/anagrafiche/utenti/utenti.component';
import { AuditlogComponent } from './pages/anagrafiche/auditlog/auditlog.component';
import { ProdottiComponent } from './pages/anagrafiche/prodotti/prodotti.component';
import { ModelloMatriceComponent } from './pages/anagrafiche/modelli-matrici/modelli-matrici.component';
import { SistemiAnalisiComponent } from './pages/anagrafiche/sistemi-analisi/sistemi-analisi.component';
import { ImpiantiProduzionePrimariaComponent } from './pages/anagrafiche/impianti-produzione-primaria/impianti-produzione-primaria.component';
import { EditorMatriciComponent } from './pages/anagrafiche/editor-matrici/editor-matrici.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: LoggedLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: '',
        canActivateChild: [permissionGuard],
        children: [
          { path: 'aziende', component: AziendeComponent },
          { path: 'nazioni', component: NazioniComponent },
          { path: 'utenti', component: UtentiComponent },
          { path: 'stabilimenti', component: StabilimentiComponent },
          { path: 'modelliMatrici', component: ModelloMatriceComponent },
          { path: 'device', component: DeviceComponent },
          { path: 'device/modelli', component: ModelliComponent },
          { path: 'device/produttori', component: ProduttoriComponent },
          { path: 'auditlog', component: AuditlogComponent },
          { path: 'prodotti', component: ProdottiComponent },
          { path: 'sistemiAnalisi', component: SistemiAnalisiComponent },
          { path: 'impianti-produzione-primaria', component: ImpiantiProduzionePrimariaComponent },
          { path: 'editor', component: EditorMatriciComponent },
        ]
      }
    ]
  },
  { path: '**', component: NotFoundComponent },
];
