import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import AuthService from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { DropdownLingueComponent } from '../../../components/cfm-dropdown-lingue/cfm-dropdown-lingue.component';
import LocalStorageService from '../../../services/local-storage.service';

@Component({
  selector: 'app-topbar',
  standalone: true,
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    DropdownLingueComponent,
		RouterModule
  ],
})
export class TopbarComponent {

  @Output() showSidebar = new EventEmitter<boolean>();  

  constructor(
    private _autenticazioneService: AuthService,
    private _localStorageService: LocalStorageService
  ) {}

  public profileSidebarVisible: boolean = false;

  public get fullnameCurrentUser(): string {
    return this._localStorageService.fullnameCurrentUser;
  }

  public onMenuToggle(): void {
    this.profileSidebarVisible = !this.profileSidebarVisible; // Cambia lo stato della barra laterale
    this.showSidebar.emit(this.profileSidebarVisible); //invia lo stato della barra laterale

  }

  public showProfileSidebar(): void {
    
  }

  public onClickLogout(): void {
    this._autenticazioneService.logout();
  }
}
