import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';
import { INazione } from './nazioni.service';
import { IImmagine } from './immagini.service';

export interface ICentro
{
  id : number | null;
  idCorp01003Nazione : number;
  idCorp01007Immagine : number;
  location : string;
  indirizzo : string;
  fusoOrario : number;
  sigla : string;
  inizioValidita : Date;
  fineValidita :  Date;

  Nazione : INazione;
  Logo : IImmagine;
}

@Injectable({
  providedIn: 'root',
})
export class CentroService extends BaseService<ICentro> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/centri', CountType.Yes, _httpClient);
  }
}
