import { Component, ViewChild } from '@angular/core';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { IImpiantoProduzionePrimaria, ImpiantiProduzionePrimariaService } from '../../../services/impianti-produzione-primaria.service';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CentroService, ICentro } from '../../../services/centri.service';
import { MenuItem } from 'primeng/api';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { Menu, MenuModule } from 'primeng/menu';
import { IFindResult } from '../../../services/base.service';
import { Observable, forkJoin } from 'rxjs';
import { ITipoImpiantoRiempimento, TipiImpiantiRiempimentoService } from '../../../services/tipi-impianti-riempimento.service';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';

class ImpiantiProduzionePrimariaFormItem {
  public id?: number;
  public idCorp01004Centro!: number;
  public idCorp09020TipoImpiantoRiempimento!: number;
  public barcode: string;
  public inizioValidita: Date;
  public fineValidita: Date | undefined;
  public centro?: ICentro;
  public tipoImpiantoRiempimento?: ITipoImpiantoRiempimento;

  constructor(init: IImpiantoProduzionePrimaria) {
    this.id = init.id;
    this.idCorp01004Centro = init.idCorp01004Centro;
    this.idCorp09020TipoImpiantoRiempimento = init.idCorp09020TipoImpiantoRiempimento;
    this.barcode = init.barcode; 
    this.inizioValidita = init.inizioValidita; 
    this.fineValidita = init.fineValidita; 
    this.centro = init.centro ?? {} as ICentro;
    this.tipoImpiantoRiempimento = init.tipoImpiantoRiempimento ?? {} as ITipoImpiantoRiempimento;
  }

  public static ConvertToImpiantoProduzionePrimaria(impiantoProduzionePrimaria: ImpiantiProduzionePrimariaFormItem): IImpiantoProduzionePrimaria {

    return <IImpiantoProduzionePrimaria>{
      id: impiantoProduzionePrimaria.id,
      idCorp01004Centro: impiantoProduzionePrimaria.idCorp01004Centro,
      idCorp09020TipoImpiantoRiempimento: impiantoProduzionePrimaria.idCorp09020TipoImpiantoRiempimento,
      barcode: impiantoProduzionePrimaria.barcode,
      inizioValidita: impiantoProduzionePrimaria.inizioValidita,
      fineValidita: impiantoProduzionePrimaria.fineValidita,
      centro: impiantoProduzionePrimaria.centro,
      tipoImpiantoRiempimento: impiantoProduzionePrimaria.tipoImpiantoRiempimento,
    };
  }
}

@Component({
  selector: 'app-impianti-produzione-primaria',
  standalone: true,
  imports: [
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    ButtonModule,
    CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
    TranslocoModule,
    MenuModule
  ],
  templateUrl: './impianti-produzione-primaria.component.html',
  styleUrl: './impianti-produzione-primaria.component.scss'
})
export class ImpiantiProduzionePrimariaComponent {

  @ViewChild('tableImpiantiProduzionePrimaria') tableImpiantiProduzionePrimaria!: CfmTableComponent<IImpiantoProduzionePrimaria>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<ImpiantiProduzionePrimariaFormItem>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof ImpiantiProduzionePrimariaFormItem, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento/modifica azienda
  public centri: ICentro[] = [];
  public tipiImpiantiRiempimento: ITipoImpiantoRiempimento[] = [];
  public impiantiProduzionePrimaria: IImpiantoProduzionePrimaria[] = [];
  public items: MenuItem[] | undefined;

  constructor(private _translocoService: TranslocoService,private _centriService: CentroService, private _cfmMessageService:CfmMessageService, private _impiantiProduzionePrimariaService: ImpiantiProduzionePrimariaService, private _tipiImpiantiRiempimentoService: TipiImpiantiRiempimentoService ) {
    this.formValidators.set('idCorp01004Centro', [Validators.required]);
    this.formValidators.set('idCorp09020TipoImpiantoRiempimento', [Validators.required]);
    this.formValidators.set('barcode', [Validators.required, Validators.maxLength(256)]);
    this.formValidators.set('inizioValidita', [Validators.required]);

    this.loadCentri().subscribe(result => this.centri = result.data);
    this.loadTipiImpiantiRiempimento().subscribe(result => this.tipiImpiantiRiempimento = result.data);
  }

  public loadCentri(): Observable<IFindResult<ICentro>> {
    return this._centriService.find();
  }

  public loadTipiImpiantiRiempimento(): Observable<IFindResult<ITipoImpiantoRiempimento>> {
    return this._tipiImpiantiRiempimentoService.find();
  }
  
  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoImpiantoProduzionePrimaria';
    else return 'ModificaImpiantoProduzionePrimaria';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  public openMenu(menu: Menu, event: MouseEvent, item: IImpiantoProduzionePrimaria) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(item)
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(item),
                  command: () => this.onClickDelete(item.id!)
                }
            ]
        }
    ];
  }

  public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
  }
  
  public onClickEdit(impiantoProduzionePrimaria: IImpiantoProduzionePrimaria): void {
    this.formMode = 'edit';
    const impiantoProduzionePrimariaToEdit = new ImpiantiProduzionePrimariaFormItem(impiantoProduzionePrimaria);
    this.newEditSidebarForm.showOnEditMode(impiantoProduzionePrimariaToEdit);
  }

  public onClickDelete(id: number): void {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneImpiantoProduzionePrimaria'),
        accept: () => this.deleteImpiantoProduzionePrimaria(id),
      }
    );
  }

  public deleteImpiantoProduzionePrimaria(id: number) {
    this._impiantiProduzionePrimariaService.delete(id).subscribe((_) => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneImpiantoProduzionePrimariaAvvenuta'));
      this.tableImpiantiProduzionePrimaria.loadItems();
    });
  }

  public loadImpiantiProduzionePrimaria(event?: OnLoadItemsEvent<IImpiantoProduzionePrimaria>): void {
    const relatedEntities: Array<string> = ['centro', 'tipoImpiantoRiempimento'];
    this._impiantiProduzionePrimariaService.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
        this.impiantiProduzionePrimaria = result.data;
        this.totalCount = result.count;
    });
  }

  public onFormSave(impiantoProduzionePrimaria: ImpiantiProduzionePrimariaFormItem) {
    impiantoProduzionePrimaria.centro = undefined
    impiantoProduzionePrimaria.tipoImpiantoRiempimento = undefined
    if (this.formMode == null) return;
    const impiantiProduzionePrimariaToInsertOrUpdate = ImpiantiProduzionePrimariaFormItem.ConvertToImpiantoProduzionePrimaria(impiantoProduzionePrimaria)
    this.formMode === 'add' ? this.insertImpiantoProduzionePrimaria(impiantiProduzionePrimariaToInsertOrUpdate) : this.updateImpiantoProduzionePrimaria(impiantiProduzionePrimariaToInsertOrUpdate);
  }

  private insertImpiantoProduzionePrimaria(impiantoProduzionePrimaria: IImpiantoProduzionePrimaria): void {
    this._impiantiProduzionePrimariaService.insert(impiantoProduzionePrimaria).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableImpiantiProduzionePrimaria.loadItems();
    });
  }

  private updateImpiantoProduzionePrimaria(impiantoProduzionePrimaria: IImpiantoProduzionePrimaria): void {
    this._impiantiProduzionePrimariaService.update(impiantoProduzionePrimaria.id, impiantoProduzionePrimaria).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableImpiantiProduzionePrimaria.loadItems();
    });
  }

  public isDeleteDisabled(impiantoProduzionePrimaria: IImpiantoProduzionePrimaria) {
    if (impiantoProduzionePrimaria.inizioValidita < new Date() && impiantoProduzionePrimaria.fineValidita === null) return false;
    
    return impiantoProduzionePrimaria.inizioValidita > new Date() || (impiantoProduzionePrimaria.fineValidita !== undefined && impiantoProduzionePrimaria.fineValidita < new Date());
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IImpiantoProduzionePrimaria>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
