import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';

export interface IAuditlog{
  id: number;
  codiceStabilimento: string;
  country: string;
  gruppo: string;
  sorgente: string;
  codiceEvento: string;
  descrizione: string;
  dettagli: string;
  verDettagli: string;
  timestamp: Date
}
@Injectable({
  providedIn: 'root'
})
export class AuditlogService extends BaseService<IAuditlog> {

  constructor(_httpClient: HttpClient){
    super('audit-log-api/audit-log', CountType.Yes, _httpClient);
  }
}
