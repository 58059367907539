<a class="version-link" (click)="showDialog()"> Ver. {{ versionAngular }}</a>
<p-dialog header="Versioni" [modal]="true" [(visible)]="visible" [style]="{width: '50vw'}" [maximizable]="true">
    <div class="card">
        <p-table [value]="versions" [scrollable]="true" [scrollHeight]="'300px'">
            <ng-template pTemplate="header">
                <tr>
                    <th>Microservizio</th>
                    <th>Versione</th>
                    <th>Data</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-version>
                <tr>
                    <td>{{ version.microservizio }}</td>
                    <td>{{ version.versione }}</td>
                    <td>{{ version.data }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>

