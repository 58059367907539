import { Component, Input } from '@angular/core';

@Component({
  selector: 'cfm-page-header',
  standalone: true,
  imports: [],
  templateUrl: './cfm-page-header.component.html',
  styleUrl: './cfm-page-header.component.scss'
})
export class CfmPageHeaderComponent {
  @Input({ required: true }) public title!: string;
}
