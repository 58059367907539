<cfm-page-header title="{{ 'ConfigurazioneAzienda' | transloco }}"></cfm-page-header>
<cfm-table
  #tableAziende
  headerIcon="fa-solid fa-building"
  title="{{ 'ListaAziende' | transloco }}"
  [items]="aziende"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadAziende($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true" [style]="{'width': '5rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Logo' | transloco }}" fieldName="logo">
    <ng-template #content let-logo>
    {{ logo.nomeFileImmagine }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'RagioneSociale' | transloco }}" fieldName="ragioneSociale" [orderable]="true" [filterable]="true" filterType="text"></cfm-table-col>
  <cfm-table-col header="{{ 'Sigla' | transloco }}" fieldName="sigla" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità'| transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
    #newEditSidebarForm
    [formTitle]="formTitle | transloco"
    [validators]="formValidators"
    (onSave)="onFormSave($event)"
    (onChange)="onFormChange($event)"
    (onFileUploaded)="onImageUploaded($event)"
    >
  <cfm-sidebar-form-item
  label="{{ 'Logo' | transloco }}"
  key="logo"
  type="upload"
  [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'RagioneSociale' | transloco }}"
    key="ragioneSociale"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Sigla' | transloco }}"
    key="sigla"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
		[maxDate]="inizioValiditaMaxDate"
  />
  <cfm-sidebar-form-item
    label="{{ 'FineValidità'| transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
		[minDate]="fineValiditaMinDate"
    [disabled]="isFineValiditaDisabled"
  />
</cfm-sidebar-form>
