import { Component, ViewChild } from '@angular/core';
import { CfmTableComponent , OnLoadItemsEvent} from '../../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../../components/cfm-table-col/cfm-table-col.component';
import { CfmPageHeaderComponent } from '../../../../components/cfm-page-header/cfm-page-header.component';
import { AbstractControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DeviceService, IDevice, StatoDeviceMobile } from '../../../../services/device.service';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from "../../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component";
import { CfmMessageService } from '../../../../services/cfm-message.service';
import { CommonModule, DatePipe } from '@angular/common';
import { IStabilimento, StabilimentiService } from '../../../../services/stabilimenti.service';
import { IModelloDeviceMobile, ModelliService } from '../../../../services/modelli-device-mobili.service';
import { Observable } from 'rxjs';
import { IFindResult } from '../../../../services/base.service';
import { QRCodeModule } from 'angularx-qrcode';
import { DialogModule } from 'primeng/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { EnumDescriptor, enumKeysToArray } from '../../../../utils/enum';

@Component({
    selector: 'app-device',
    standalone: true,
    templateUrl: './device.component.html',
    styleUrl: './device.component.scss',
    imports: [
        ReactiveFormsModule,
        CfmPageHeaderComponent,
        CfmTableComponent,
        CfmTableColComponent,
        CommonModule,
        DatePipe,
        ButtonModule,
        CfmSidebarFormComponent,
        CfmSidebarFormItemComponent,
        QRCodeModule,
        DialogModule,
        TranslocoModule,
        MenuModule
    ]
})

export class DeviceComponent {
  @ViewChild('tableDevice') public tableDevice!: CfmTableComponent<IDevice>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IDevice>;

  public visible: boolean = false;
  public qrCodeSource!: SafeResourceUrl | null;
  public device: IDevice[] = [];
  public stabilimenti: IStabilimento[] = [];
  public stati: Array<EnumDescriptor<StatoDeviceMobile>> = enumKeysToArray(StatoDeviceMobile, "number");
  public modelli: IModelloDeviceMobile[] = [];

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IDevice, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();
  public items: MenuItem[] = [];

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoDevice';
    else return 'ModificaDevice';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  constructor(
    private _sanitizer: DomSanitizer, 
    private _translocoService: TranslocoService, 
    private _deviceService: DeviceService, 
    private _stabilimentoService: StabilimentiService, 
    private _modelloService: ModelliService,
    private _cfmMessageService:CfmMessageService
  ) {
    this.formValidators.set('idCorp01004Centro', [Validators.required]);
    this.formValidators.set('idCorp06002ModelloDeviceMobile', [Validators.required]);
    this.formValidators.set('inizioValidita', [Validators.required]);
    this.formValidators.set('numeroSeriale', [Validators.required, Validators.maxLength(100)]);

    //  caricamento dei dati dei filtri
    this.loadStabilimenti().subscribe(result => this.stabilimenti = result.data);
    this.loadModelli().subscribe(result => this.modelli = result.data);
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy'; 
  }

  public getKeyStatoDevice(device: IDevice): string | undefined {
    return this.stati.find(s => s.value == device.stato)?.key;
  }

  public openMenu(menu: Menu, event: MouseEvent, device: IDevice) {
    menu.toggle(event);

    const menuItems: MenuItem[] = [
      {
        label: this._translocoService.translate<string>('Modifica'),
        icon: 'pi pi-user-edit',
        iconStyle: {'color': '#E0A800'},
        command: () => this.onClickEdit(device)
      },
      {
        label: this._translocoService.translate<string>('Cancella'),
        icon: 'pi pi-trash',
        iconStyle: {'color': 'red'},
        disabled: this.isDeleteDisabled(device),
        command: () => this.onClickDelete(device.id)
      }
    ];

    if (device.stato == StatoDeviceMobile.NonRegistrato) {
      menuItems.push({
        label: this._translocoService.translate<string>('CodiceQR'),
        icon: 'pi pi-qrcode',
        iconStyle: {'color': 'green'},
        command: () => this.showDeviceConfigurationDialog(device.id)
      });
    }

    if (device.stato == StatoDeviceMobile.Abilitato) {
      menuItems.push({
        label: this._translocoService.translate<string>('Disabilita'),
        icon: 'pi pi-ban',
        iconStyle: {'color': 'grey'},
        command: () => this.changeDeviceStatus(device, StatoDeviceMobile.Registrato)
      });
    }

    if (device.stato == StatoDeviceMobile.Registrato) {
      menuItems.push({
        label: this._translocoService.translate<string>('Abilita'),
        icon: 'pi pi-check',
        iconStyle: {'color': '#1E90FF'},
        command: () => this.changeDeviceStatus(device, StatoDeviceMobile.Abilitato)
      });
    }

    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: menuItems
      }
    ];
  }

  public changeDeviceStatus(device: IDevice, status: StatoDeviceMobile) {
    device.stato = status;
    device.centro = null;
    device.modello = null;
    this._deviceService.update(device.id, device).subscribe(() => {
      this._cfmMessageService.showSuccessMessage('Stato del device aggiornato con successo.');
      this.tableDevice.loadItems();
    });
  }

  public loadDevice(event?: OnLoadItemsEvent<IDevice>): void {
    const relatedEntities: Array<string> = ['centro', 'stato', 'modello', 'stato.etichettaTraduzione'];
    this._deviceService.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
      this.device = result.data;
      this.totalCount = result.count;
    });
  }

  public loadStabilimenti(): Observable<IFindResult<IStabilimento>> {
    return this._stabilimentoService.find();
  }

  public loadModelli(): Observable<IFindResult<IModelloDeviceMobile>> {
    return this._modelloService.find();
  }

  public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
  }

  public onClickEdit(device: IDevice): void {
    this.formMode = 'edit';
    this.newEditSidebarForm.showOnEditMode(device);
  }

  public onFormSave(device: IDevice): void {
    if (this.formMode == null) return;
    this.formMode == 'add' ? this.insertDevice(device) : this.updateDevice(device);
  }

  private insertDevice(device: IDevice): void {
    this._deviceService.insert(device).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableDevice.loadItems();
    });
  }

  private updateDevice(device: IDevice): void {
    device.centro = null;
    device.modello = null;
    this._deviceService.update(device.id, device).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableDevice.loadItems();
    });
  }

  public onClickDelete(id: number){
    this._cfmMessageService.showConfirmDialog(
    {
      header: this._translocoService.translate<string>('Attenzione'),
      message: this._translocoService.translate<string>('ConfermaEliminazioneDevice'),
      accept:()=> this.deleteDevice(id),
    },
    )
  }

  public deleteDevice(id: number) {
    this._deviceService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneDeviceAvvenuta'));
      this.tableDevice.loadItems();
    });
  }

  public isDeleteDisabled(device: IDevice) {
    if (device.inizioValidita < new Date() && device.fineValidita === null) return false;

    return device.inizioValidita > new Date() || (device.fineValidita !== undefined && device.fineValidita < new Date());
  }

  public showDeviceConfigurationDialog(deviceId: number) {
    this._deviceService.getQRCodeForConfiguration(deviceId).subscribe(result => {
      this.qrCodeSource = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ result);
      this.visible = true;
    });
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IDevice>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
