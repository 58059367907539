import { Component } from '@angular/core';
import { ModalVersionComponent } from "../../../components/cfm-modal-version/cfm-modal-version.component";

@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    imports: [ModalVersionComponent]
})
export class FooterComponent {

}
