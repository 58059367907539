import { ChangeDetectorRef, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { StepperModule } from 'primeng/stepper';
import { CfmStepEditorComponent } from '../../../components/cfm-step-editor/cfm-step-editor.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-editor-matrici',
  standalone: true,
  imports: [ButtonModule, StepperModule, CfmStepEditorComponent, CommonModule],
  templateUrl: './editor-matrici.component.html',
  styleUrls: ['./editor-matrici.component.scss']
})
export class EditorMatriciComponent {
  public header: string = 'TestHeader';
  public content: string = 'TestContenuto';
  public label: string = 'Crea step successivo';
  public loading: boolean = false;
  public active: number = 0;

  steps: { header: string; content: string; label: string, type: boolean }[] = [
    { header: 'Step 1', content: 'Content 1', label: 'Crea step successivo', type: false},
  ];

  constructor(private _cdr: ChangeDetectorRef) {}
  addStep(): void {
    const stepNumber = this.steps.length + 1;
    const newStep = {
      header: `Step ${stepNumber}`,
      content: `Content ${stepNumber}`,
      label: `Crea step successivo`,
      type: false,
    };

    this.loading = true;
    this.steps.push(newStep);
    this.updateButtonLabels();
  }

  private async updateButtonLabels(): Promise<void> {
    if (this.steps.length > 1) {
      const previousStepIndex = this.steps.length - 2; 
      this.steps[previousStepIndex].label = `Go to Step ${this.steps.length}`;
      this.steps[previousStepIndex].type = true;
      this._cdr.detectChanges(); 
      this.loading = false;      
    }
  }
}
