import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';
import { ICentro } from './centri.service';
import { IModelloDeviceMobile } from './modelli-device-mobili.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

export enum StatoDeviceMobile {
  NonRegistrato = 0,
  Registrato = 1,
  Abilitato = 2,
  Disabilitato = 3
}

export interface IDevice
{
  id: number;
  idCorp01004Centro: number;
  versioneSistemaOperativo: string;
  stato: StatoDeviceMobile;
  idCorp06002ModelloDeviceMobile: number;
  guid: string;
  numeroSeriale: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  centro: ICentro | null;
  modello: IModelloDeviceMobile | null;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends BaseService<IDevice> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/device-mobili', CountType.Yes, _httpClient);
  }

  public getQRCodeForConfiguration(id: number): Observable<any> {
    return this._httpClient.get<IDevice>(
      `${environment.baseApi}/service-db-api/device-mobili/${id}/qrcode`
    );
  }
}


