<cfm-page-header title="{{ 'ConfigurazioneProdotti' | transloco }}"></cfm-page-header>
<cfm-table
  #tableProdotti
  headerIcon="fa-solid fa-globe"
  title="{{ 'ListaProdotti' | transloco }}"
  [items]="prodotti"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadProdotti($event)"
  (onAdd)="onClickAdd()"

>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true" [style]="{'width': '7rem'}"
  ></cfm-table-col>
  <cfm-table-col header="{{ 'DenominazioneProdotto' | transloco }}" fieldName="denominazione" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Codice' | transloco }}" fieldName="codice" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '16rem'}">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità'| transloco }}" fieldName="fineValidita"[orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '12rem'}">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  (onChange)="onFormChange($event)"
  >
    <cfm-sidebar-form-item
      label="{{ 'DenominazioneProdotto' | transloco }}"
      key="denominazione"
      type="text"
      [colWidth]="12"
    />
    <cfm-sidebar-form-item
      label="{{ 'CodiceProdotto' | transloco }}"
      key="codice"
      type="text"
      [colWidth]="12"
    />
    <cfm-sidebar-form-item
      label="{{ 'Azienda' | transloco }}"
      key="associazioniProdottoAzienda"
      type="multiSelect"
      dropdownOptionLabel="azienda.sigla"
      [dropdownOptions]="associazioniProdottoAzienda"
      [colWidth]="12"
      [disabled]="isAssociazioniAziendaDisabled"
    />
    <cfm-sidebar-form-item
      label="{{ 'Nazione' | transloco }}"
      key="associazioniProdottoNazione"
      type="multiSelect"
      dropdownOptionLabel="nazione.sigla"
      [dropdownOptions]="associazioniProdottoNazione"
      [colWidth]="12"
      [disabled]="isAssociazioniNazioneDisabled"
    />
    <cfm-sidebar-form-item
      label="{{ 'Stabilimento' | transloco }}"
      key="associazioniProdottoCentro"
      type="multiSelect"
      dropdownOptionLabel="centro.sigla"
      [dropdownOptions]="associazioniProdottoCentro"
      [colWidth]="12"
      [disabled]="isAssociazioniCentroDisabled"
    />
      <cfm-sidebar-form-item
        label="{{ 'Utente'| transloco }}"
        key="utentiAssegnatiProdotto"
        type="multiSelect"
        dropdownOptionLabel="utente.anagraficaUtente"
        [dropdownOptions]="tempUtenteProdotto"
        [colWidth]="12"
        [minDate]="fineValiditaMinDate"
        [disabled]="!_authService.isSuperUser || !isUserMultiSelectDisabled"
      />
    <cfm-sidebar-form-item
      label="{{ 'InizioValidità' | transloco }}"
      key="inizioValidita"
      type="date"
      [dateFormat]="getDateFormat"
      [colWidth]="6"
      [maxDate]="inizioValiditaMaxDate"
      />
      <cfm-sidebar-form-item
      label="{{ 'FineValidità'| transloco }}"
      key="fineValidita"
      type="date"
      [dateFormat]="getDateFormat"
      [colWidth]="6"
      [minDate]="fineValiditaMinDate"
      [disabled]="isFineValiditaDisabled"
    />
</cfm-sidebar-form>
