import { Component, ViewChild } from '@angular/core';
import { CfmPageHeaderComponent } from "../../../components/cfm-page-header/cfm-page-header.component";
import { CfmTableComponent, OnLoadItemsEvent } from "../../../components/cfm-table/cfm-table.component";
import { CfmTableColComponent } from "../../../components/cfm-table-col/cfm-table-col.component";
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from "../../../components/cfm-sidebar-form/cfm-sidebar-form.component";
import { CfmSidebarFormItemComponent } from "../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component";
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ISistemaAnalisi, SistemiAnalisiService } from '../../../services/sistemi-analisi.service';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { CentroService, ICentro } from '../../../services/centri.service';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IFindResult } from '../../../services/base.service';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-sistemi-analisi',
    standalone: true,
    templateUrl: './sistemi-analisi.component.html',
    styleUrl: './sistemi-analisi.component.scss',
    imports: [CfmPageHeaderComponent, CfmTableComponent, CfmTableColComponent, CfmSidebarFormComponent, CfmSidebarFormItemComponent, ButtonModule, TranslocoModule, DatePipe, MenuModule]
})
export class SistemiAnalisiComponent {
  @ViewChild('tableSistemiAnalisi') public tableSistemiAnalisi!: CfmTableComponent<ISistemaAnalisi>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<ISistemaAnalisi>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof ISistemaAnalisi, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();
  public formItem: ISistemaAnalisi | null = null;

  // Proprietà utili alla form di inserimento/modifica
  public centri: ICentro[] = [];
  public sistemiAnalisi: ISistemaAnalisi[] = [];
  public items: MenuItem[] | undefined;

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoSistemaAnalisi';
    else return 'ModificaSistemaAnalisi';
  }

  constructor(private _translocoService: TranslocoService,private _sistemiAnaliSiservice: SistemiAnalisiService, private _cfmMessageService:CfmMessageService, private _centriService: CentroService) {
    
    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(256)]);
    this.formValidators.set('barcode', [Validators.maxLength(256)]);
    this.formValidators.set('idCorp01004Centro', [Validators.required]);
    this.formValidators.set('inizioValidita', [Validators.required]);

    this.loadCentri().subscribe(result => this.centri = result.data);

  }

  public loadCentri(): Observable<IFindResult<ICentro>> {
      return this._centriService.find();
    }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  public openMenu(menu: Menu, event: MouseEvent, sistemaAnalisi: ISistemaAnalisi) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(sistemaAnalisi)
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(sistemaAnalisi),
                  command: () => this.onClickDelete(sistemaAnalisi.id)
                }
            ]
        }
    ];
  }

  public loadSistemiAnalisi(event?: OnLoadItemsEvent<ISistemaAnalisi>): void {
    const relatedEntities: Array<string> = ['centro'];
      this._sistemiAnaliSiservice.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
          this.sistemiAnalisi = result.data;
          this.totalCount = result.count;
      });
  }

  public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
  }

  public onClickEdit(sistemaAnalisi: ISistemaAnalisi): void {
    this.formMode = 'edit';
    const sistemaAnalisiToEdit = { ...sistemaAnalisi };
    this.newEditSidebarForm.showOnEditMode(sistemaAnalisiToEdit);
  }

  public onFormSave(sistemaAnalisi: ISistemaAnalisi): void {
    sistemaAnalisi.centro = undefined;
    if (this.formMode == null) return;
    this.formMode === 'add' ? this.insertSistemaAnalisi(sistemaAnalisi) : this.updateSistemaAnalisi(sistemaAnalisi);
  }

  private insertSistemaAnalisi(sistemaAnalisi: ISistemaAnalisi): void {
    this._sistemiAnaliSiservice.insert(sistemaAnalisi).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableSistemiAnalisi.loadItems();
    });
  }

  private updateSistemaAnalisi(sistemaAnalisi: ISistemaAnalisi): void {
    this._sistemiAnaliSiservice.update(sistemaAnalisi.id, sistemaAnalisi).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableSistemiAnalisi.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaCancellazioneSistemaAnalisi'),
        accept: () => this.deleteModello(id),
      }
    );
  }

  public isDeleteDisabled(sistemaAnalisi: ISistemaAnalisi) {
    if (!sistemaAnalisi.fineValidita) return false;
    return sistemaAnalisi.fineValidita >= new Date(Date.now()) ? false : true;
  }

  public deleteModello(id: number){
    this._sistemiAnaliSiservice.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneSistemaAnalisiAvvenuta'));
      this.tableSistemiAnalisi.loadItems();
    });
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<ISistemaAnalisi>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
