import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';

export interface IImmagine {
  id?: number;
  nomeFileImmagine: string;
  binarioImmagine: string;
  binarioImmagineB64: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImmaginiService extends BaseService<IImmagine> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/immagini', CountType.Yes, _httpClient);
  }
}
