import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { TopbarComponent } from '../topbar/topbar.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-logged-layout',
  standalone: true,
  imports: [RouterOutlet, TopbarComponent, FooterComponent, SidebarComponent, CommonModule],
  templateUrl: './logged-layout.component.html',
  styleUrl: './logged-layout.component.scss'
})
export class LoggedLayoutComponent {
  public showSidebar: boolean = false;

  public get containerClass() {
    return {
      "layout-static-inactive": !this.showSidebar
    };
  }

  public handleSidebarChange() {
    this.showSidebar = !this.showSidebar;
  }
}