<cfm-page-header title="{{ 'ConfigurazioneAuditlog' | transloco }}"></cfm-page-header>
<cfm-table
  #tableAuditlog
  headerIcon="fa-solid fa-clipboard-list"
  title="{{ 'ListaAuditlog' | transloco }}"
  [items]="auditlog"
  [totalItemsCount]="totalCount"
  [showAddButton]="false"
  (onLoadItems)="loadAuditlog($event)"
  (onAdd)="null"
  >

  <cfm-table-col header="Id" fieldName="id" [orderable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'CodiceStabilimento' | transloco }}" fieldName="codiceStabilimento" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Country' | transloco }}" fieldName="country" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Gruppo' | transloco }}" fieldName="gruppo" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Sorgente' | transloco }}" fieldName="sorgente" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'CodiceEvento' | transloco }}" fieldName="codiceEvento" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Descrizione' | transloco }}" fieldName="descrizione" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Dettagli' | transloco }}" fieldName="dettagli" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'VerDettagli' | transloco }}" fieldName="verDettagli" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Data' | transloco }}" fieldName="timestamp" [orderable]="true" [filterable]="true">
    <ng-template #content let-timestamp>
      {{ timestamp | dateFormatterPipe }}
    </ng-template>
  </cfm-table-col>
  
  
</cfm-table>

