import { Injectable } from '@angular/core';
import { ICentro } from './centri.service';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ITipoImpiantoRiempimento } from './tipi-impianti-riempimento.service';

export interface IImpiantoProduzionePrimaria {
  id: number;
  idCorp01004Centro: number;
  idCorp09020TipoImpiantoRiempimento: number;
  barcode: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  centro: ICentro;
  tipoImpiantoRiempimento: ITipoImpiantoRiempimento;
}

@Injectable({
  providedIn: 'root'
})
export class ImpiantiProduzionePrimariaService extends BaseService<IImpiantoProduzionePrimaria> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/impianti-produzione-primaria', CountType.Yes, _httpClient);
  }
}
