import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { CfmSidebarFormItemComponent, FormItemType } from '../cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FileSelectEvent, FileUpload, FileUploadModule } from 'primeng/fileupload';
import { IImmagine } from '../../services/immagini.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { Subscription } from 'rxjs';
import { SortPipe } from '../../pipe/sort-pipe';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitPipe } from "../../pipe/split.pipe";

export interface OnFormItemChangeEvent<T> {
  ctrlName: keyof T;
  newValue: any;
  formItemValue: any;
}

@Component({
    selector: 'cfm-sidebar-form',
    standalone: true,
    templateUrl: './cfm-sidebar-form.component.html',
    styleUrl: './cfm-sidebar-form.component.scss',
    imports: [
        CommonModule,
        SidebarModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        FormsModule,
        FileUploadModule,
        InputNumberModule,
        SortPipe,
        TranslocoModule,
        MultiSelectModule,
        SplitPipe
    ]
})
export class CfmSidebarFormComponent<T> implements AfterContentInit {
  @ViewChild('fileUpload') fileUpload!: FileUpload;
  @ContentChildren(CfmSidebarFormItemComponent) formItemComponents!: QueryList<CfmSidebarFormItemComponent<T>>;

  @Input({ required: true }) public formTitle!: string;
  @Input() public validators: Map<keyof T, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> | null = null;
  @Output() public onChange: EventEmitter<OnFormItemChangeEvent<T>> = new EventEmitter();
  @Output() public onSave: EventEmitter<T> = new EventEmitter();
  @Output() public onFileUploaded: EventEmitter<File> = new EventEmitter<File>();

  public sidebarVisible: boolean = false;
  public formItem: T | null = null;
  public formGroup!: FormGroup<Record<keyof T, FormControl<any>>>;
  public fileSelected: File | null = null;

  private formGroupSubscription: Subscription | null = null;
  private formGroupSubscriptions: Subscription[] = [];

  constructor(private _translocoService: TranslocoService ,private _formBuilder: FormBuilder) {}

  ngAfterContentInit(): void {
    const group = <any> {};

    this.formItemComponents.forEach(formItem => {
      const initialValue = this.getFormItemInitalValue(formItem);
      const validators = this.validators != null && this.validators.has(formItem.key) ? this.validators.get(formItem.key)! : [];
      group[formItem.key] = [{ value: initialValue, disabled: formItem.disabled }, validators];

      formItem.onDisabledChange.subscribe(toDisable => {
        if (toDisable) this.formGroup.controls[formItem.key].disable();
        else this.formGroup.controls[formItem.key].enable();
      });
    });

    this.formGroup = this._formBuilder.group(group) as any;
}

  ngOnDestroy(): void {
    this.removeFormGroupValueChangeSubscription();
  }

  /**
 * Imposta il valore di un determinato elemento del form group.
 *
 * @param formItemKey - La chiave dell'elemento del form per impostare il valore.
 * @param newValue - Il nuovo valore da impostare per l'elemento del form.
 * @remarks
 * Questo metodo viene utilizzato per aggiornare il valore di un determinato elemento del form group.
 * Utilizza i controlli del form group per impostare il valore per la chiave dell'elemento del form specificata.
 */
  public setFormItemValue(formItemKey: keyof T, newValue: T[typeof formItemKey]) {
    this.formGroup.controls[formItemKey].setValue(newValue);
  }

  public getUploadedFile(key: keyof T): string {
    if (this.formItem == null) return '';
    const immagine = this.formItem[key] as IImmagine;
    return immagine.nomeFileImmagine;
  }

  public formItemIsRequired(formItemKey: keyof T): boolean {
    return this.formGroup.controls[formItemKey].hasValidator(Validators.required);
  }

  public show(): void {
    this.addFormGroupValueChangeSubscription();
    this.sidebarVisible = true;
    this.setFormInitalValue();
  }

  public showOnEditMode(itemToEdit: T) {
    this.sidebarVisible = true;
    this.formItem = { ...itemToEdit };
    for (const ctrl in this.formGroup.controls) {
      this.formGroup.controls[ctrl].setValue(this.formItem[ctrl as keyof T]);
    }
    this.addFormGroupValueChangeSubscription();
  }

  public hide(): void {
    this.sidebarVisible = false;
    this.clearForm();
  }

  public clearForm(): void {
    this.removeFormGroupValueChangeSubscription();
    this.fileUpload?.clear();
    this.formItem = null;
    this.fileSelected = null;
  }

  public onClickSave() {
    if (this.formItem) this.onSave.emit(this.formItem);
  }

  public onSelect(event: FileSelectEvent, formItemKey: keyof T) {
    this.fileSelected = event.files[0];
    var validExtensions = this.fileUpload.accept?.split(",");
    var uploadedFileExt = "."+this.fileSelected.name.split(".").pop();
    const isExtValid = (ext: string) => ext === uploadedFileExt;

    if (validExtensions?.some(isExtValid)) {
      this.formGroup.controls[formItemKey].setValue(this.fileSelected);
      this.onFileUploaded.emit(this.fileSelected);
    }
  }

  public formItemHasValidationError(formItemKey: keyof T): boolean {
    return this.formGroup.controls[formItemKey].touched 
      && this.formGroup.controls[formItemKey].dirty 
      && this.formGroup.controls[formItemKey].errors != null;
  }

  public getFormItemValidationErrorMessage(formItemKey: keyof T) {
    const errors = this.formGroup.controls[formItemKey].errors;
    if (errors == null) return '';

    const firstErrorKey = Object.keys(errors)[0];

    if (firstErrorKey == Validators.required.name) return this._translocoService.translate<string>('CampoObbligatorio'); //'Il campo è obbligatorio';
    else if (firstErrorKey == Validators.minLength.name.toLowerCase()) return this._translocoService.translate<string>('LunghezzaMin') + errors[firstErrorKey].requiredLength; // 'La lunghezza minima consentita è '
    else if (firstErrorKey == Validators.maxLength.name.toLowerCase()) return this._translocoService.translate<string>('LunghezzaMax') + errors[firstErrorKey].requiredLength; // 'La lunghezza massima consentita è '
    else if (firstErrorKey == Validators.min.name.toLowerCase()) return this._translocoService.translate<string>('ValoreMin') + errors[firstErrorKey].min; // 'Il valore minimo consentito è '
    else if (firstErrorKey == Validators.max.name.toLowerCase()) return this._translocoService.translate<string>('ValoreMax') + errors[firstErrorKey].max; // 'Il valore massimo consentito è '
    else if (firstErrorKey == Validators.email.name.toLowerCase()) return this._translocoService.translate<string>('EmailConsentita') + errors[firstErrorKey].email; // "L'email consentita è "
    else return this._translocoService.translate<string>('ErroreValidazione'); //'Errore di validazione non gestito'
  }

  private addFormGroupValueChangeSubscription(): void {
    if (this.formGroupSubscription != null) return;

    for (const ctrl in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(ctrl)) {
        const subscription = this.formGroup.controls[ctrl as keyof T].valueChanges.subscribe(_ => {
            this.updateFormItemCtrlValue(ctrl as keyof T);
        });
        this.formGroupSubscriptions.push(subscription);
      }
    }
  }

  private updateFormItemCtrlValue(ctrlName: keyof T) {
    const valueToSave = <T> {};

    const formItem = this.formItemComponents.find(item => item.key == ctrlName);
    let value = this.formGroup.controls[ctrlName].value ?? this.getFormItemDefaultValueFromType(formItem?.type);

    valueToSave[ctrlName as keyof T] = value;

    this.formItem = Object.assign(this.formItem ?? {}, valueToSave);

    this.onChange.emit({
      ctrlName: ctrlName,
      newValue: value,
      formItemValue: this.formItem
    });
  }

  private removeFormGroupValueChangeSubscription(): void {
    for (const subscription of this.formGroupSubscriptions) {
      subscription.unsubscribe();
    }
    this.formGroupSubscriptions = [];
  }

  private setFormInitalValue() {
    this.formItemComponents.forEach(formItem => {
      const initialValue = this.getFormItemInitalValue(formItem);
      this.formGroup.controls[formItem.key].setValue(initialValue);
      this.formGroup.controls[formItem.key].markAsPristine();
    });
  }

  private getFormItemInitalValue(formItem: CfmSidebarFormItemComponent<T>) {
    if (formItem.defaultValue) return formItem.defaultValue;
    return this.getFormItemDefaultValueFromType(formItem.type);
  }

  private getFormItemDefaultValueFromType(formItemType?: FormItemType) {
    switch (formItemType) {
      case 'date': return undefined;
      case 'dropdown': return null;
      case 'multiSelect': return [];
      case 'number': return 0;
      case 'text': return '';
      default: return '';
    }
  }
}
