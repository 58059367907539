import { Component, ViewChild } from '@angular/core';
import { IStabilimento, StabilimentiService } from '../../../services/stabilimenti.service';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { DatePipe } from '@angular/common';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { ButtonModule } from 'primeng/button';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { IImmagine, ImmaginiService } from '../../../services/immagini.service';
import { INazione, NazioniService } from '../../../services/nazioni.service';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from "../../../components/cfm-sidebar-form/cfm-sidebar-form.component";
import { CfmSidebarFormItemComponent } from "../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component";
import { Observable, forkJoin } from 'rxjs';
import { IFindResult } from '../../../services/base.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
@Component({
    selector: 'app-stabilimenti',
    standalone: true,
    templateUrl: './stabilimenti.component.html',
    styleUrl: './stabilimenti.component.scss',
    imports: [
        CfmPageHeaderComponent,
        CfmTableComponent,
        CfmTableColComponent,
        DatePipe,
        ButtonModule,
        CfmSidebarFormComponent,
        CfmSidebarFormItemComponent,
        TranslocoModule,
        MenuModule
    ]
})
export class StabilimentiComponent {
  @ViewChild('tableStabilimenti') public tableStabilimenti!: CfmTableComponent<IStabilimento>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IStabilimento>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IStabilimento, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento/modifica stabilimento
  public stabilimenti: IStabilimento[] = [];
  public nazioni: INazione[] = [];
  public loghi: IImmagine[] = [];
  public formItem: IStabilimento | null = null;
  public imageData!: IImmagine;
  public items: MenuItem[] = [];

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get fineValiditaMaxDate(): Date | null {
    const idNazione = this.newEditSidebarForm?.formItem?.idCorp01003Nazione;
    if (idNazione == null) return null;
    
    return this.nazioni.find(a => a.id === idNazione)?.fineValidita ?? null;
  }

  public get inizioValiditaMinDate(): Date | null {
    const idNazione = this.newEditSidebarForm?.formItem?.idCorp01003Nazione;
    if (idNazione == null) return null;
    return this.nazioni.find(a => a.id === idNazione)?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  constructor(private _translocoService: TranslocoService,private _stabilimentiService: StabilimentiService, private _nazioniService: NazioniService, private _cfmMessageService:CfmMessageService, private _immaginiService: ImmaginiService) {
    this.formValidators.set('idCorp01003Nazione', [Validators.required]);
    this.formValidators.set('idCorp01007Immagine', [Validators.required]);
    this.formValidators.set('logo', [Validators.required]);
    this.formValidators.set('location', [Validators.required, Validators.maxLength(50)]);
    this.formValidators.set('indirizzo', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('fusoOrario', [Validators.required, Validators.min(-12), Validators.max(12)]);
    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(50)]);
    this.formValidators.set('inizioValidita', [Validators.required]);
    
    //  caricamento dei dati dei filtri
    this.loadNazioni().subscribe(result => this.nazioni = result.data);
  }

  public get formTitle(): string {
    if (this.formMode == 'add') return 'Nuovo stabilimento';
    else return 'Modifica stabilimento';
  }

  openMenu(menu: Menu, event: MouseEvent, stabilimento: IStabilimento) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(stabilimento)              
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(stabilimento),
                  command: () => this.onClickDelete(stabilimento.id)
                }
            ]
        }
    ];
  }

  public loadNazioni(): Observable<IFindResult<INazione>> {
    return this._nazioniService.find();
  }

  public loadStabilimenti(event: OnLoadItemsEvent<IStabilimento>): void {
    const relatedEntities: Array<keyof IStabilimento> = ['nazione', 'logo'];
    this._stabilimentiService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy, relatedEntities).subscribe(result => {
      this.stabilimenti = result.data;
      this.totalCount = result.count;
    });
  }

  public onClickAdd():void {
    this.formMode = 'add';
    this.loadNazioni().subscribe((result) => {
      this.nazioni = result.data;
      this.newEditSidebarForm.show();
    })
  }

  public onClickEdit(stabilimento: IStabilimento): void {
    this.loadNazioni().subscribe(result => {
      this.nazioni = result.data;
      stabilimento.logo = { nomeFileImmagine: stabilimento.logo.nomeFileImmagine, binarioImmagine: stabilimento.logo.binarioImmagine, binarioImmagineB64: stabilimento.logo.binarioImmagineB64 };
      this.formMode = 'edit';
      this.newEditSidebarForm.showOnEditMode(stabilimento);
    });
  }


  public onFormSave(stabilimento: IStabilimento) {
    if (this.formMode == null) return;
    this.formMode === 'add' ? this.insertStabilimento(stabilimento) : this.updateStabilimento(stabilimento);
  }

  private insertStabilimento(stabilimento: IStabilimento): void {
    stabilimento.logo = this.imageData;
    this._stabilimentiService.insert(stabilimento).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableStabilimenti.loadItems();
    });
  }

  private updateStabilimento(stabilimento: IStabilimento): void {
    stabilimento.nazione = null;
    if (stabilimento.logo.binarioImmagine == null) stabilimento.logo = this.imageData;
    this._stabilimentiService.update(stabilimento.id, stabilimento).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableStabilimenti.loadItems();
    });
  }

  public onClickDelete(id: number){
    this._cfmMessageService.showConfirmDialog(
    {
        header: this._translocoService.translate<string>('Attenzione'),
        message:  this._translocoService.translate<string>('ConfermaCancellazioneStabilimento'),
        accept: () => this.deleteStabilimento(id)
    },
    );
  }

  public deleteStabilimento(id: number) {
    this._stabilimentiService.delete(id).subscribe(_ => {
    this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneStabilimentoAvvenuta'),);
    this.tableStabilimenti.loadItems();
    });
  }

  public isDeleteDisabled(stabilimento: IStabilimento) {
    if (stabilimento.inizioValidita < new Date() && stabilimento.fineValidita === null) return false;
    
    return stabilimento.inizioValidita > new Date() || (stabilimento.fineValidita !== undefined && stabilimento.fineValidita < new Date());
  }

  public onImageUploaded(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageData = {
        nomeFileImmagine: file.name,
        binarioImmagine: '',
        binarioImmagineB64: reader.result ? reader.result.toString() : ''
      };
    };
    reader.readAsDataURL(file);
  }
  /**
 * Gestore dell'evento di modifica dell'elemento del form.
 * Questo metodo viene chiamato ogni volta che il valore di un elemento del form cambia.
 * Controlla se l'elemento modificato è 'inizioValidita' e se il nuovo valore è null.
 * Se entrambi i criteri sono soddisfatti, imposta il valore dell'elemento del form 'fineValidita' a undefined.
 */
  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IStabilimento>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
    else if (onFormItemChangeEvent.ctrlName == 'idCorp01003Nazione' && onFormItemChangeEvent.newValue !== undefined) {
      this.newEditSidebarForm.setFormItemValue('inizioValidita', undefined);
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
