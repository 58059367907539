<cfm-page-header title="{{'ConfigurazioneDevice' | transloco }}"></cfm-page-header>
<cfm-table
  #tableDevice
  headerIcon="fa-solid fa-computer"
  title="{{'ListaDevice' | transloco }}"
  [items]="device"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadDevice($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true" [style]="{'width': '5rem'}"></cfm-table-col>
  <cfm-table-col header="{{'Stabilimento' | transloco }}" fieldName="centro.Sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01004Centro" [filterOptions]="stabilimenti" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item="item">
      {{ item.centro?.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{'VersioneSistemaOperativo' | transloco }}" fieldName="versioneSistemaOperativo" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{'StatoDeviceMobile' | transloco }}" fieldName="stato" [orderable]="true" [filterable]="true" filterBy="stato" [filterOptions]="stati" filterOptionLabel="key" filterOptionValue="value">
    <ng-template #content let-item="item">
      {{ getKeyStatoDevice(item) | transloco }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{'ModelloDeviceMobile' | transloco }}" fieldName="modello.Sigla" [orderable]="true" [filterable]="true" filterBy="idCorp06002ModelloDeviceMobile"  [filterOptions]="modelli" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item="item">
      {{ item.modello?.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{'GUID' | transloco }}" fieldName="guid" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{'NumeroSeriale' | transloco }}" fieldName="numeroSeriale" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '10rem'}">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{'FineValidità' |transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
        <p-dialog header="{{'Registradevice' | transloco }}" [modal]="true" [(visible)]="visible"
                  [style]="{ width: '45rem', height:'28rem' }"
                  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
            <img class="qrCode" [src]="qrCodeSource" />
        </p-dialog>
    </ng-template>
</cfm-table-col>

</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  (onChange)="onFormChange($event)"
>
  <cfm-sidebar-form-item
    label="{{'Stabilimento' | transloco }}"
    key="idCorp01004Centro"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="stabilimenti"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{'ModelloDevice' | transloco }}"
    key="idCorp06002ModelloDeviceMobile"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="modelli"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{'NumeroSeriale' | transloco }}"
    key="numeroSeriale"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [maxDate]="inizioValiditaMaxDate"
  />
  <cfm-sidebar-form-item
    label="{{'FineValidità' | transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="fineValiditaMinDate"
    [disabled]="isFineValiditaDisabled"
  />
</cfm-sidebar-form>
