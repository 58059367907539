{
  "name": "cfm.portale",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-aruba": "ng build --configuration aruba",
    "build-dev": "ng build --configuration dev",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.0",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@fortawesome/fontawesome-free": "^6.5.2",
    "@jsverse/transloco": "^7.0.0",
    "@ngneat/transloco": "^6.0.4",
    "@ngx-translate/core": "^15.0.0",
    "angularx-qrcode": "^17.0.0",
    "jwt-decode": "^4.0.0",
    "primeflex": "^3.3.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.11.0",
    "quill": "^1.3.7",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.2",
    "@angular/cli": "^17.2.2",
    "@angular/compiler-cli": "^17.2.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
