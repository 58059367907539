<cfm-page-header title="{{ 'ConfigurazioneUtenti' | transloco }}"></cfm-page-header>
<cfm-table
  #tableUtenti
  headerIcon="fa-solid fa-users"
  title="{{ 'ListaUtenti' | transloco }}"
  [items]="utenti"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadUtenti($event)"
  (onAdd)="onClickAdd()">

  <cfm-table-col header="Id" fieldName="id" [orderable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'SistemaInformativoGestionale' | transloco }}" fieldName="sistemaInformativoGestionale.sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01005SistemaInformativoGestionale"
  [filterOptions]="sistemaInformativoGestionali" filterOptionLabel="sigla" filterOptionValue="id">>
    <ng-template #content let-item='item'>
      {{ item.sistemaInformativoGestionale.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'AnagraficaUtente' | transloco }}" fieldName="anagraficaUtente" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="UserId" fieldName="userid" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'UsernameFirmaElettronica' | transloco }}" fieldName="usernameFirmaElettronica" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Azienda' | transloco }}" fieldName="Azienda.Sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01002Azienda"
    [filterOptions]="aziende" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item='item'>
      {{ item.azienda.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Nazione' | transloco }}" fieldName="Nazione.Sigla" [orderable]="true"[filterable]="true" filterBy="idCorp01003Nazione"
  [filterOptions]="nazioni" filterOptionLabel="sigla" filterOptionValue="id">>
    <ng-template #content let-item='item'>
      {{item?.nazione?.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Stabilimento' | transloco }}" fieldName="Centro.Sigla" [orderable]="true"[filterable]="true" filterBy="idCorp01004Centro"
  [filterOptions]="centri" filterOptionLabel="sigla" filterOptionValue="id">>
    <ng-template #content let-item='item'>
      {{ item?.centro?.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true"
  filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità' | transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true"  filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  (onChange)="onFormChange($event)"
  (onChange)="onChange($event)"
>
  <cfm-sidebar-form-item
    label="UserId"
    key="userid"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'AnagraficaUtente' | transloco }}"
    key="anagraficaUtente"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'UsernameFirmaElettronica' | transloco }}"
    key="usernameFirmaElettronica"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'ProfiloUtente' | transloco }}"
    key="profili"
    type="multiSelect"
    dropdownOptionLabel="denominazione"
    [dropdownOptions]="profili"
    [colWidth]="12"
    [disabled]="!_authService.isSuperUser"
  />
  <cfm-sidebar-form-item
    label="{{ 'Azienda' | transloco }}"
    key="idCorp01002Azienda"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="aziende"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Nazione' | transloco }}"
    key="idCorp01003Nazione"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="nazioniSidebar"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Stabilimento' | transloco }}"
    key="idCorp01004Centro"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="centriSidebar"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'SistemaInformativoGestionale' | transloco }}"
    key="idCorp01005SistemaInformativoGestionale"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="sistemaInformativoGestionali"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [maxDate]="inizioValiditaMaxDate"
  />
  <cfm-sidebar-form-item
    label="{{ 'FineValidità' | transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="fineValiditaMinDate"
    [disabled]="isFineValiditaDisabled"
  />
</cfm-sidebar-form>
