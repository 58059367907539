<div class="layout-menu" [@animation]>
    <ul>
        <div class="layout-root-menuitem">
            <li class="layout-menuitem-root-text">
                <i class="fa-solid fa-home"></i>
                Home
            </li>
            <app-sidebar-item [routerLink]="['dashboard']">
                <i icon class="layout-menuitem-icon fa-solid fa-gauge-high"></i>
                <span text>Dashboard</span>
			</app-sidebar-item>
        </div>

        <hr class="item-separator">
          <div class="layout-root-menuitem">
              <li class="layout-menuitem-root-text">
                <i class="fa-solid fa-address-book"></i>
                {{ 'GestioneAnagrafiche' | transloco}}
              </li>
              <app-sidebar-item [routerLink]="['aziende']">
                  <i icon class="layout-menuitem-icon fa-solid fa-building"></i>
                  <span>{{ 'GestioneAziende' | transloco}}</span>
              </app-sidebar-item>
              <app-sidebar-item [routerLink]="['nazioni']">
                  <i icon class="layout-menuitem-icon fa-solid fa-globe"></i>
                  <span>{{ 'GestioneNazioni' | transloco}}</span>
              </app-sidebar-item>
              <app-sidebar-item [routerLink]="['stabilimenti']">
                  <i icon class="layout-menuitem-icon fa-solid fa-warehouse"></i>
                  <span>{{ 'GestioneStabilimenti' | transloco}}</span>
              </app-sidebar-item>
							<app-sidebar-item [routerLink]="['prodotti']">
                <i icon class="layout-menuitem-icon fa-solid fa-fire-flame-simple"></i>
                <span>{{ 'GestioneProdotti' | transloco}}</span>
						  </app-sidebar-item>
              <app-sidebar-item [routerLink]="['utenti']">
                  <i icon class="layout-menuitem-icon fa-solid fa-users"></i>
                  <span>{{ 'GestioneUtenti' | transloco}}</span>
              </app-sidebar-item>
              <app-sidebar-item [routerLink]="['modelliMatrici']">
                <i icon class="layout-menuitem-icon fa-solid fa-file"></i>
                <span>{{ 'GestioneModelliMatrici' | transloco}}</span>
              </app-sidebar-item>
              <app-sidebar-item [routerLink]="['impianti-produzione-primaria']">
                <i icon class="layout-menuitem-icon fa-solid fa-network-wired"></i>
                <span>{{ 'GestioneImpianti' | transloco}}</span>
              </app-sidebar-item>
          </div>
        <hr class="item-separator">

        <div class="layout-root-menuitem">
            <li class="layout-root-menuitem layout-menuitem-root-text">
							<i class="fa-solid fa-sliders"></i>
              {{ 'ConfigurazioneDevice' | transloco}}
            </li>
            <app-sidebar-item [routerLink]="['device']">
                <i icon class="layout-menuitem-icon fa-solid fa-computer"></i>
                <span>{{ 'GestioneDevice' | transloco}}</span>
            </app-sidebar-item>
            <app-sidebar-item [routerLink]="['device/produttori']">
                <i icon class="layout-menuitem-icon fa-solid fa-gears"></i>
                <span>{{ 'GestioneProduttoriDevice' | transloco}}</span>
            </app-sidebar-item>
            <app-sidebar-item [routerLink]="['device/modelli']">
                <i icon class="layout-menuitem-icon fa-solid fa-mobile-retro"></i>
                <span>{{ 'GestioneModelliDevice' | transloco}}</span>
            </app-sidebar-item>
            <app-sidebar-item [routerLink]="['sistemiAnalisi']">
                <i icon class="layout-menuitem-icon fa-solid fa-bar-chart"></i>
                <i class="fa-solid fa-objects-align-bottom"></i>
                <span>{{ 'GestioneSistemiAnalisi' | transloco}}</span>
            </app-sidebar-item>
        </div>
        <hr class="item-separator">

            <div class="layout-root-menuitem">
            <li class="layout-root-menuitem layout-menuitem-root-text">
                <i class="fa-solid fa-user-secret"></i>
                {{ 'AuditLog' | transloco}}
            </li>
            <app-sidebar-item [routerLink]="['auditlog']">
                <i icon class="layout-menuitem-icon fa-solid fa-clipboard-list"></i>
                <span>{{ 'ConsultazioneAuditLog' | transloco}}</span>
            </app-sidebar-item>
        </div>
    </ul>
</div>
