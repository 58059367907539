<div class="col-12">
    <div class="title-paragraph-container">
        <div class="row">
            <div class="page-header">
                <div class="col">
                    <h2 class="page-heading" style="padding-top: 0">
                        {{ title }}
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>