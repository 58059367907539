<cfm-page-header title="{{ 'ConfigurazioneStabilimenti' | transloco }}"></cfm-page-header>
<cfm-table
  #tableStabilimenti
  headerIcon="fa-solid fa-warehouse"
  title="{{ 'ListaStabilimenti' | transloco }}"
  [items]="stabilimenti"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadStabilimenti($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true" [style]="{'width': '5rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Nazione' | transloco }}" fieldName="Nazione.Sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01003Nazione" [filterOptions]="nazioni" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item='item'>
      {{item.nazione.sigla  }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Logo' | transloco }}" fieldName="idCorp01007Immagine">
    <ng-template #content let-item='item'>
      {{item.logo.nomeFileImmagine  }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Location' | transloco }}" fieldName="location" [orderable]="true" [filterable]="true" [style]="{'width': '10rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Indirizzo' | transloco }}" fieldName="indirizzo" [orderable]="true" [filterable]="true" [style]="{'width': '10rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'FusoOrario' | transloco }}" fieldName="fusoOrario" [orderable]="true" [filterable]="true" filterMode="equals" [style]="{'width': '10rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Sigla' | transloco }}" fieldName="sigla" [orderable]="true" [filterable]="true" [style]="{'width': '10rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '12rem'}">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità' | transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '10rem'}">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  (onChange)="onFormChange($event)"
  (onFileUploaded)="onImageUploaded($event)"
  >
    <cfm-sidebar-form-item
    label="{{ 'Nazione' | transloco }}"
    key="idCorp01003Nazione"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="nazioni"
    [colWidth]="12"
    />

    <cfm-sidebar-form-item
    label="{{ 'Logo' | transloco }}"
    key="logo"
    type="upload"
    [colWidth]="12"
    />

    <cfm-sidebar-form-item
      label="{{ 'Location' | transloco }}"
      key="location"
      type="text"
      [colWidth]="12"
    />

    <cfm-sidebar-form-item
      label="{{ 'Indirizzo' | transloco }}"
      key="indirizzo"
      type="text"
      [colWidth]="12"
    />

    <cfm-sidebar-form-item
      label="{{ 'FusoOrario' | transloco }}"
      key="fusoOrario"
      type="number"
      [colWidth]="12"
    />

    <cfm-sidebar-form-item
      label="{{ 'Sigla' | transloco }}"
      key="sigla"
      type="text"
      [colWidth]="12"
    />

    <cfm-sidebar-form-item
      label="{{ 'InizioValidità' | transloco }}"
      key="inizioValidita"
      type="date"
      [dateFormat]="getDateFormat"
      [colWidth]="6"
      [minDate]="inizioValiditaMinDate"
      [maxDate]="fineValiditaMaxDate"
      />
    <cfm-sidebar-form-item
      label="{{ 'FineValidità' | transloco }}"
      key="fineValidita"
      type="date"
      [dateFormat]="getDateFormat"
      [colWidth]="6"
      [minDate]="fineValiditaMinDate"
      [maxDate]="fineValiditaMaxDate"
      [disabled]="isFineValiditaDisabled"
      />
  </cfm-sidebar-form>
