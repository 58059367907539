import { Component, ViewChild } from '@angular/core';
import { AziendeService, IAzienda } from '../../../services/aziende.service';
import { CommonModule, DatePipe } from '@angular/common';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { AbstractControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { IImmagine } from '../../../services/immagini.service';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-aziende',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
    TranslocoModule,
    ButtonModule,
    CommonModule,
    MenuModule
  ],
  templateUrl: './aziende.component.html',
  styleUrl: './aziende.component.scss',
})
export class AziendeComponent {
  @ViewChild('tableAziende') public tableAziende!: CfmTableComponent<IAzienda>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IAzienda>;

  public aziende: IAzienda[] = [];
  public formItem: IAzienda | null = null;
  public imageData!: IImmagine;
  public totalCount: number = 0;
  public items: MenuItem[] | undefined;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IAzienda, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();
  

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovaAzienda';
    else return 'ModificaAzienda';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  constructor(private _translocoService: TranslocoService, private _aziendeService: AziendeService, private _cfmMessageService:CfmMessageService) {
    this.formValidators.set('ragioneSociale', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('logo', [Validators.required]);
    this.formValidators.set('inizioValidita', [Validators.required]);
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }
  
  public openMenu(menu: Menu, event: MouseEvent, azienda: IAzienda) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(azienda)    
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(azienda),
                  command: () => this.onClickDelete(azienda.id)
                }
            ]
        }
    ];
  }

  public loadAziende(event?: OnLoadItemsEvent<IAzienda>): void {
    const relatedEntities: Array<keyof IAzienda> = ['logo'];
    this._aziendeService.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
        this.aziende = result.data;
        this.totalCount = result.count;
    });
  }

  public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
  }

  public onClickEdit(azienda: IAzienda): void {
    this.formMode = 'edit';
    const aziendaToEdit = { ...azienda };
    this.newEditSidebarForm.showOnEditMode(aziendaToEdit);  
  }

  public onFormSave(azienda: IAzienda): void {
    if (this.formMode == null) return;
    this.formMode == 'add' ? this.insertAzienda(azienda) : this.updateAzienda(azienda);
  }

  private insertAzienda(azienda: IAzienda): void {
    azienda.id = 0;
    azienda.logo = this.imageData;
    this.formMode =  'add';
    this._aziendeService.insert(azienda).subscribe(() => {
        this.newEditSidebarForm.hide();
        this.tableAziende.loadItems();
    });
  }

  private updateAzienda(azienda: IAzienda): void {
    if(azienda.logo.binarioImmagine == null) azienda.logo = this.imageData;
    this._aziendeService.update(azienda.id, azienda).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableAziende.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneAzienda'),
        accept: () => this.deleteAzienda(id),
      }
    );
  }
  
  deleteAzienda(id:number){
    this._aziendeService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneAziendaAvvenuta'));
      this.tableAziende.loadItems();
    });
  }

  public isDeleteDisabled(azienda: IAzienda): boolean {
    if (azienda.inizioValidita < new Date() && azienda.fineValidita === null) return false;
    
    return azienda.inizioValidita > new Date() || (azienda.fineValidita !== undefined && azienda.fineValidita < new Date());
  }

  public onImageUploaded(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageData = {
        nomeFileImmagine: file.name,
        binarioImmagine: '',
        binarioImmagineB64: reader.result ? reader.result.toString() : ''
      };
    };
    reader.readAsDataURL(file);
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IAzienda>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
