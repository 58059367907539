<cfm-page-header title="{{ 'ConfigurazioneNazioni' | transloco }}"></cfm-page-header>
<cfm-table
  #tableNazioni
  headerIcon="fa-solid fa-globe"
  title="{{ 'ListaNazioni' | transloco }}"
  [items]="nazioni"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadNazioni($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true" [style]="{'width': '7rem'}"
  ></cfm-table-col>
  <cfm-table-col header="{{ 'Logo' | transloco }}" fieldName="logo" [style]="{'width': '3rem'}">
    <ng-template #content let-logo>
      {{ logo.nomeFileImmagine }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'SistemaInformativoGestionale' | transloco }}" fieldName="sistemaInformativoGestionale.sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01005SistemaInformativoGestionale" [filterOptions]="sistemaInformativoGestionali" filterOptionLabel="sigla" filterOptionValue="id" [style]="{'width': '20rem'}">
    <ng-template #content let-item="item">
    {{item.sistemaInformativoGestionale.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Azienda' | transloco }}" fieldName="azienda.sigla" [orderable]="true" [filterable]="true" filterBy="idCorp01002Azienda" [filterOptions]="aziende" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item="item">
    {{item.azienda.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'CodiceLotto' | transloco }}" fieldName="codiceNazioneLotto" [orderable]="true" [filterable]="true" [style]="{'width': '18rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Sigla' | transloco }}" fieldName="sigla" [orderable]="true" [filterable]="true" [style]="{'width': '10rem'}"></cfm-table-col>
  <cfm-table-col header="{{ 'Lingua' | transloco }}" fieldName="Lingua.Sigla" [orderable]="true" [filterable]="true" filterBy="idCorp03001Lingua" [filterOptions]="lingue" filterOptionLabel="sigla" filterOptionValue="id" [style]="{'width': '5rem'}">
    <ng-template #content let-item="item">
    {{item.lingua.sigla }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '16rem'}">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità'| transloco }}" fieldName="fineValidita"[orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy" [style]="{'width': '12rem'}">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  (onChange)="onFormChange($event)"
  (onFileUploaded)="onImageUploaded($event)"
>
  <cfm-sidebar-form-item
    label="{{ 'Azienda' | transloco }}"
    key="idCorp01002Azienda"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="aziende"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Logo' | transloco }}"
    key="logo"
    type="upload"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'SistemaInformativoGestionale' | transloco }}"
    key="idCorp01005SistemaInformativoGestionale"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="sistemaInformativoGestionali"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Lingua' | transloco }}"
    key="idCorp03001Lingua"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="lingue"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'CodiceLotto' | transloco }}"
    key="codiceNazioneLotto"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Sigla' | transloco }}"
    key="sigla"
    type="text"
    [colWidth]="12"

  />
  <cfm-sidebar-form-item
    label="{{ 'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="inizioValiditaMinDate"
    [maxDate]="fineValiditaMaxDate"
  />
    <cfm-sidebar-form-item
    label="{{ 'FineValidità'| transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="fineValiditaMinDate"
    [maxDate]="fineValiditaMaxDate"
    [disabled]="isFineValiditaDisabled"
  />
</cfm-sidebar-form>
