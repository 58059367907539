import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'cfm-dashboard-card',
  standalone: true,
  imports: [CardModule, ButtonModule, CommonModule],
  templateUrl: './cfm-dashboard-card.component.html',
  styleUrl: './cfm-dashboard-card.component.scss'
})
export class CfmDashboardCardComponent {

  @Input({ required: true }) title!: string;
  @Input({ required: true }) value!: string ;
  @Input({ required: true }) iconImageUrl!: string;
  @Input({ required: true }) backgroundColor!: string;
  @Input({ required: true }) backgroundImage!: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  onclick() {
    this.onClick.emit();
  }


}
