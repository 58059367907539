
<form class="mt-3" (ngSubmit)="login()">
  <div class="flex flex-column mb-5">
    <label for="username" class="w-full text-xl font-medium mb-2">{{ 'UsernameLabel' | transloco }}: {{ username }}</label>
    <label for="password" class="w-full text-xl font-medium mb-2">Password</label>
    <input
      id="password"
      type="password"
      class="w-full md:w-30rem mb-3"
      placeholder="Password"
      pPassword
      [(ngModel)]="password"
      [feedback]="false"
      [ngModelOptions]="{ standalone: true }"
    />
    <button
      pButton
      type="submit"
      title="Submit"
      pRipple
      label="Login"
      class="button_login w-full text-xl mb-3"
      [disabled]="!password"
    ></button>
  </div>
</form>
