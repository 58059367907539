import { Component, ViewChild } from '@angular/core';
import { CfmPageHeaderComponent } from "../../../components/cfm-page-header/cfm-page-header.component";
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CfmTableComponent, OnLoadItemsEvent } from "../../../components/cfm-table/cfm-table.component";
import { AuditlogService, IAuditlog } from '../../../services/auditlog.service';
import { CfmSidebarFormComponent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { CfmTableColComponent } from "../../../components/cfm-table-col/cfm-table-col.component";
import { DateFormatterPipePipe } from '../../../pipe/date-formatter-pipe.pipe';

@Component({
    selector: 'app-auditlog',
    standalone: true,
    templateUrl: './auditlog.component.html',
    styleUrl: './auditlog.component.scss',
    imports: [CfmPageHeaderComponent, TranslocoModule, CfmTableComponent, CfmTableColComponent, DateFormatterPipePipe]
})
export class AuditlogComponent {

  @ViewChild('tableAuditlog') tableNazioni!: CfmTableComponent<IAuditlog>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IAuditlog>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IAuditlog, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento/modifica Auditlog
  public auditlog: IAuditlog[] = [];

  constructor(private _translocoService: TranslocoService,private _auditlogService: AuditlogService, private _cfmMessageService:CfmMessageService) {

  }

  public loadAuditlog(event: OnLoadItemsEvent<IAuditlog>): void {
    const relatedEntities: Array<keyof IAuditlog> = [];
    this._auditlogService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy, relatedEntities).subscribe(result => {
        this.auditlog = result.data;
        this.totalCount = result.count;
    });
  }
}
