<div class="title-paragraph-container">
    <div class="row">
        <div class="container">
            <div class="page-header">
                <div class=" col">
                    <h2 class="page-heading">
                        Benvenuti in SOL-POC la piattaforma di configurazione dei sistemi di gestione palmari.
                    </h2>
                </div>
                <div class=" col">
                    <p class="text-header"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et efficitur odio. Nam ac velit dui. Ut lacus dui, aliquam a convallis sit amet, molestie ut eros. Pellentesque at semper ipsum, non efficitur lacus. Fusce ac dictum elit, volutpat posuere sem. Mauris posuere ex metus, sit amet laoreet justo vehicula in.<br /> Cras non purus arcu. Aliquam egestas finibus magna at aliquam. Aenean pulvinar, diam sit amet faucibus faucibus, orci magna molestie velit, ac pharetra risus nisl sit amet odio.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid dashboard-card-grid">
    <cfm-dashboard-card
        class="col-6 lg:col-3 md:col-3 xl:col-3"
        title="Stabilimenti"
        value="3"
        backgroundColor="#78d2f7"
        backgroundImage="/assets/layout/images/themes/img-03.jpg"
        iconImageUrl="assets/layout/images/themes/ico-01-col.png"
        (onClick)="handleCardClick()"
    >
    </cfm-dashboard-card>
    <cfm-dashboard-card
        class="col-6 lg:col-3 md:col-3 xl:col-3"
        title="Stabilimenti"
        value="3"
        backgroundColor="#78d2f7"
        backgroundImage="/assets/layout/images/themes/img-03.jpg"
        iconImageUrl="assets/layout/images/themes/ico-01-col.png"
        (onClick)="handleCardClick()"
    >
    </cfm-dashboard-card>
    <cfm-dashboard-card
        class="col-6 lg:col-3 md:col-3 xl:col-3"
        title="Stabilimenti"
        value="3"
        backgroundColor="#78d2f7"
        backgroundImage="/assets/layout/images/themes/img-03.jpg"
        iconImageUrl="assets/layout/images/themes/ico-01-col.png"
        (onClick)="handleCardClick()"
    >
    </cfm-dashboard-card>
    <cfm-dashboard-card
        class="col-6 lg:col-3 md:col-3 xl:col-3"
        title="Stabilimenti"
        value="3"
        backgroundColor="#78d2f7"
        backgroundImage="/assets/layout/images/themes/img-03.jpg"
        iconImageUrl="assets/layout/images/themes/ico-01-col.png"
        (onClick)="handleCardClick()"
    >
    </cfm-dashboard-card>
</div>

<hr class="separator" />
    