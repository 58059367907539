import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';
import { INazione } from './nazioni.service';
import { IImmagine } from './immagini.service';

export interface IStabilimento {
  id: number;
  idCorp01003Nazione: number;
  idCorp01007Immagine: number;
  location: string;
  indirizzo: string;
  fusoOrario: number;
  sigla: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  nazione: INazione | null;
  logo: IImmagine;
}

@Injectable({
  providedIn: 'root',
})
export class StabilimentiService extends BaseService<IStabilimento> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/centri', CountType.Yes, _httpClient);
  }
}
