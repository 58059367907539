import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IEtichettaTraduzione } from './etichetta-traduzione.service';

export interface ITipologiaMatrici{
  id: number;
  sigla: string;
  idEtichettaTraduzione: number;

  etichettaTraduzione : IEtichettaTraduzione | null;
}
@Injectable({
  providedIn: 'root'
})
export class TipologieMatriciService extends BaseService<ITipologiaMatrici> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/tipologie-matrici', CountType.Yes, _httpClient);
  }
}
