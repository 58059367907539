import { Component, Input } from '@angular/core';
import AuthService, {
  IAutenticazioneRequest,
} from '../../services/auth.service';
import { Router } from '@angular/router';
import LocalStorageService from '../../services/local-storage.service';
import { FormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'cfm-auth-sintesi-uk',
  templateUrl: './cfm-auth-sintesi-uk.component.html',
  styleUrl: './cfm-auth-sintesi-uk.component.scss',
  imports: [
    FormsModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    TranslocoModule,
  ],
  standalone: true,
})
export class CfmAuthSintesiUkComponent {
  @Input({ required: true }) username: string = '';

  public password: string = '';
  
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _localstorageService: LocalStorageService,
  ) {}

  login() {
    const loginRequest: IAutenticazioneRequest = {
      username: this.username,
      password: this.password,
    };

    this._authService.login(loginRequest)
      .subscribe(loginResponse => {
        // Conservazione JWT token e della sua scadenza
        this._localstorageService.token = loginResponse.jwtToken.value;
        this._localstorageService.tokenExpiration = loginResponse.jwtToken.validTo;

        this._router.navigate(['/dashboard']);
      });
  }
}
