import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, NgModule, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'cfm-table-col',
  standalone: true,
  imports: [],
  templateUrl: './cfm-table-col.component.html',
  styleUrl: './cfm-table-col.component.scss'
})
export class CfmTableColComponent {
  @Input({ required: true }) header: string | undefined;
  @Input({ required: true }) fieldName!: string;
  @Input() orderable: boolean = false;
  @Input() filterable: boolean | string = false;
  @Input() filterBy: string | null = null;
  @Input() style: any;
  @Input() filterType: 'text' | 'numeric' | 'boolean' | 'date' = 'text';
  @Input() filterMode!: 'equals' | 'contains';
  @Input() filterOptions!: any[];
  @Input() filterOptionLabel!: string;
  @Input() filterOptionValue: string | undefined;
	@Input() filterDateFormat!: string;
  @Input() public colWidth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 = 12;

  @ContentChild("content") contentTemplateRef: TemplateRef<any> | undefined;

  formGroup!: FormGroup;


  ngOninit() {
    this.formGroup = new FormGroup({
      selectedCities: new FormControl<any[] | null>([{ name: 'dropdownOptionValue', code: 'dropdownOptionLabel' }])
    });
  }

  public get widthClass(): string {
    return 'col-' + this.colWidth;
  }

  public get matchMode(): string {
    if (this.filterMode) return this.filterMode;
    if (this.filterOptions) return 'in';

    switch(this.filterType) {
      case 'text':
        return 'contains';
      case 'date':
        return 'dateIs';
      case 'numeric':
      case 'boolean':
      default:
        return 'equals';
    }
  }
}
