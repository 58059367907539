<div class="col-12">
    <div class="card">
        <div class="card-header">
        <div class="flex justify-content-between mb-3">
            <h5 class="lista-header m-0">
                <!-- <i class="pi" [class]="headerIcon"></i> {{ title }} -->
								<i class="{{ headerIcon }}"></i> {{ title }}
            </h5>
            <p-button *ngIf="showAddButton !==false"
                icon="pi pi-plus"
                label="{{ 'Aggiungi' | transloco }}"
                (click)="onClickAdd()"
                styleClass="button-18"
            ></p-button>
        </div>
        </div>
        <div class="card-body p-0">
            <p-table
                styleClass="p-datatable-striped p-datatable-sm mb-2 table-head-fixed text-nowrap"
                currentPageReportTemplate="{{'Mostra' | transloco}} {{'Da' | transloco}} {first} {{'A' | transloco}} {last} {{'Di' | transloco}} {totalRecords} {{'Voci' | transloco}}"                
                [value]="items"
                [lazy]="true"
                (onLazyLoad)="loadItems($event)"
                [paginator]="true"
                [totalRecords]="totalItemsCount"
                [rowsPerPageOptions]="rowsPerPageOptions"
                [(rows)]="pageSize"
                [first]="indexFirstElement"
                [alwaysShowPaginator]="true"
                [showCurrentPageReport]="true"
            >
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="30">
                    <p class="text-center">Nessun dato trovato</p>
                    </td>
                </tr>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <ng-container *ngFor="let cc of columnComponents">
                            <th *ngIf="cc.orderable === true" [pSortableColumn]="cc.fieldName">
                                <div class="headerTable">
                                    <span>{{ cc.header }}</span>
                                    <p-sortIcon *ngIf="cc.orderable" [field]="cc.fieldName" class="sort-icon"></p-sortIcon>
                                </div>
                            </th>
                            <th *ngIf="!cc.orderable">
                                <div class="headerTable">
                                    <span>{{ cc.header }}</span>
                                </div>
                            </th>
                        </ng-container>
                    </tr>

                    <tr *ngIf="showRowFilter">
                        <th *ngFor="let cc of columnComponents">
                            <p-columnFilter *ngIf="cc.filterable" [type]="cc.filterType" [field]="cc.filterBy || cc.fieldName" [matchMode]="cc.matchMode" [showMenu]="false">
                                <ng-template *ngIf="cc.filterOptions" pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect
                                        [ngModel]="value"
                                        [options]="cc.filterOptions"
                                        [optionLabel]="cc.filterOptionLabel"
                                        [optionValue]="cc.filterOptionValue"
                                        (onChange)="filter($event.value)"
                                        appendTo="body"
                                    >
                                        <ng-template *ngIf="cc.filterOptionLabel" let-dropdownitem pTemplate="item">
                                            {{ dropdownitem | splitAndTransloco: cc.filterOptionLabel }}
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                                    <ng-template *ngIf="cc.filterType === 'date'" pTemplate="filterDate" let-value let-filter="filterCallback">
                                        <p-calendar class="custom-calendar" [ngModel]="value" [dateFormat]="cc.filterDateFormat" (onSelect)="filter($event)" [autoZIndex]="false" [baseZIndex]="9999" appendTo="body" readonlyInput = true [showButtonBar]="true"></p-calendar>
                                    </ng-template>
                            </p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-item let-ri="rowIndex">
                        <tr>
                          <td *ngFor="let col of columnComponents">
                                <ng-container
                                    [ngTemplateOutlet]="tdTemplate"
                                    [ngTemplateOutletContext]="{ col: col, item: item }">
                                </ng-container>
                            </td>
                        </tr>

                    <ng-template #tdTemplate let-col="col" let-item="item">
                        <ng-container *ngIf="!col.contentTemplateRef; else tdCustomTemplate">{{ item[col.fieldName] }}</ng-container>
                        <ng-template #tdCustomTemplate>
                            <ng-container
                                [ngTemplateOutlet]="col.contentTemplateRef!"
                                [ngTemplateOutletContext]="{ $implicit: item[col.fieldName], item: item }"
                            ></ng-container>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

