import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CfmAuthSintesiUkComponent } from '../../components/cfm-auth-sintesi-uk/cfm-auth-sintesi-uk.component';
import { DropdownLingueComponent } from '../../components/cfm-dropdown-lingue/cfm-dropdown-lingue.component';
import { ModalVersionComponent } from '../../components/cfm-modal-version/cfm-modal-version.component';
import { TranslocoModule } from '@ngneat/transloco';
import SistemiInformativiService from '../../services/sistemi-informativi.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
    CfmAuthSintesiUkComponent,
    DropdownLingueComponent,
    ModalVersionComponent,
    TranslocoModule,
  ],
  standalone: true
})
export class LoginComponent {
  public username: string = '';
  public siglaSistemaInformativo: string = '';

  constructor(private sistemiInformativiService: SistemiInformativiService) {}

  public get showAuthSintesiUk(): boolean {
    return this.siglaSistemaInformativo === "SINTESI-UK";
  }

  async onNextLoginStep() {
    this.sistemiInformativiService
      .getByNomeUtente(this.username)
      .subscribe(sistemaInformativo => {
        this.siglaSistemaInformativo = sistemaInformativo.sigla;
      });
  }
}
