import { Component, model, ViewChild } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { CfmSidebarFormComponent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { IAssociazioneModello, IModelloMatrice, ModelliMatriciService } from '../../../services/modelli-matrici.service';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { IProdotto, ProdottiService } from '../../../services/prodotti.service';
import { Observable, forkJoin, map } from 'rxjs';
import { IFindResult } from '../../../services/base.service';
import { ITipologiaMatrici, TipologieMatriciService } from '../../../services/tipologie-matrici.service';
import { IStatoModello, StatiModelliService } from '../../../services/stati-modelli.service';
import { CentroService } from '../../../services/centri.service';
import { NazioniService } from '../../../services/nazioni.service';
import { AziendeService } from '../../../services/aziende.service';
import { Router } from '@angular/router';

class ModelloFormItem {
  public id?: number;
  public idCorp04002Prodotto!: number;
  public idCorp02002TipologiaMatrice!: number;
  public idCorp02011StatoModello!: number;
  public associazioniModelloAzienda: IAssociazioneModello[];
  public associazioniModelloNazione: IAssociazioneModello[];
  public associazioniModelloCentro: IAssociazioneModello[];
  public prodotto: IProdotto;
  public tipologiaMatrici: ITipologiaMatrici;
  public stato: IStatoModello;

  /**
   * Costruttore della classe ModelloFormItem.
   * @param init - Dati iniziali per il prodotto.
   * @param associazioniModello - Associazioni del prodotto con altre entità.
   */
  constructor(init: IModelloMatrice, associazioniModello: IAssociazioneModello[]) {
    this.id = init.id;
    this.idCorp04002Prodotto = init.idCorp04002Prodotto;
    this.idCorp02002TipologiaMatrice = init.idCorp02002TipologiaMatrice;
    this.idCorp02011StatoModello = init.idCorp02011StatoModello;
    this.associazioniModelloAzienda = associazioniModello.filter(ap => init.associazioneModelli?.map(iap => iap.idCorp01002Azienda)?.includes(ap.idCorp01002Azienda));
    this.associazioniModelloNazione = associazioniModello.filter(ap => init.associazioneModelli?.map(iap => iap.idCorp01003Nazione).includes(ap.idCorp01003Nazione));
    this.associazioniModelloCentro = associazioniModello.filter(ap => init.associazioneModelli?.map(iap => iap.idCorp01004Centro).includes(ap.idCorp01004Centro));
    this.prodotto = init.prodotto ?? {} as IProdotto;
    this.tipologiaMatrici = init.tipologiaMatrici ?? {} as ITipologiaMatrici;
    this.stato = init.stato ?? {} as IStatoModello;
  }

  /**
   * Metodo statico per convertire ModelloFormItem in un IModelloMatrice.
   * @param modelloMatrice - Il ModelloFormItem da convertire.
   * @returns Il IModelloMatrice convertito.
   */
  public static ConvertToIModelloMatrice(modelloMatrice: ModelloFormItem): IModelloMatrice {
    const associazioniModello: IAssociazioneModello[] = [];

    associazioniModello.push(...modelloMatrice.associazioniModelloAzienda?.map(item => <IAssociazioneModello>({ idCorp01002Azienda: item.idCorp01002Azienda })) || []);
    associazioniModello.push(...modelloMatrice.associazioniModelloNazione?.map(item => <IAssociazioneModello>({ idCorp01003Nazione: item.idCorp01003Nazione })) || []);
    associazioniModello.push(...modelloMatrice.associazioniModelloCentro?.map(item => <IAssociazioneModello>({ idCorp01004Centro: item.idCorp01004Centro })) || []);

    return <IModelloMatrice>{
      id: modelloMatrice.id,
      idCorp04002Prodotto: modelloMatrice.idCorp04002Prodotto,
      idCorp02002TipologiaMatrice: modelloMatrice.idCorp02002TipologiaMatrice,
      idCorp02011StatoModello: modelloMatrice.idCorp02011StatoModello,
      prodotto: modelloMatrice.prodotto,
      associazioneModelli: associazioniModello,
      tipologiaMatrici: modelloMatrice.tipologiaMatrici,
      stato: modelloMatrice.stato,
    };
  }
}

@Component({
  selector: 'app-modelli-matrici',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
    TranslocoModule,
    ButtonModule,
		TagModule,
    CommonModule,
    MenuModule
  ],
  templateUrl: './modelli-matrici.component.html',
  styleUrl: './modelli-matrici.component.scss'
})
export class ModelloMatriceComponent {
  @ViewChild('tableModelliMatrici') public tableModelliMatrici!: CfmTableComponent<IModelloMatrice>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<ModelloFormItem>;

  public modelliMatrici: IModelloMatrice[] = [];
  public prodotti: IProdotto[] = [];
  public tipologieMatrici: ITipologiaMatrici[] = [];
  public statiModelli: IStatoModello[] = [];
  public associazioneModelliAzienda: IAssociazioneModello[] = [];
  public associazioneModelliNazione: IAssociazioneModello[] = [];
  public associazioneModelliCentro: IAssociazioneModello[] = [];

  public totalCount: number = 0;
  public items: MenuItem[] = [];
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof ModelloFormItem, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  constructor(private _router: Router, private _translocoService: TranslocoService, private _modelliService: ModelliMatriciService, private _tipologieMatriciService: TipologieMatriciService, private _statiService: StatiModelliService, private _cfmMessageService: CfmMessageService, private _prodottiService: ProdottiService, private _aziendeService: AziendeService, private _nazioniService: NazioniService, private _centriService: CentroService ) {
    this.formValidators.set('idCorp04002Prodotto', [Validators.required]);

    this.loadProdotti().subscribe(result => this.prodotti = result.data);
    this.loadTipologieMatrici().subscribe(result => this.tipologieMatrici = result.data);
    this.loadStatiModelli().subscribe(result => this.statiModelli = result.data );
    this.loadAziende().subscribe(result => this.associazioneModelliAzienda = result);
    this.loadNazioni().subscribe(result => this.associazioneModelliNazione = result);
    this.loadCentri().subscribe(result => this.associazioneModelliCentro = result);
  }

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoModelloMatrice';
    else return 'ModificaModelloMatrice';
  }

  // La combobox dove selezionare le associazioni dei modelli alle aziende va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni modello-nazione e modello-centro non è stato selezionato alcun valore.
  public get isAssociazioniAziendaDisabled(): boolean {
    const associazioniNazioneSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloNazione.length ?? 0;
    const associazioniCentroSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloCentro.length ?? 0;

    return associazioniNazioneSelectedCount > 0 || associazioniCentroSelectedCount > 0;
  }

  // La combobox dove selezionare le associazioni dei modelli alle nazioni va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni modello-azienda e modello-centro non è stato selezionato alcun valore.
  public get isAssociazioniNazioneDisabled(): boolean {
    const associazioniAziendaSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloAzienda.length ?? 0;
    const associazioniCentroSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloCentro.length ?? 0;

    return associazioniAziendaSelectedCount > 0 || associazioniCentroSelectedCount > 0;
  }

  // La combobox dove selezionare le associazioni dei modelli ai centri va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni modello-azienda e modello-nazione non è stato selezionato alcun valore.
  public get isAssociazioniCentroDisabled(): boolean {
    const associazioniAziendaSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloAzienda.length ?? 0;
    const associazioniNazioneSelectedCount = this.newEditSidebarForm?.formItem?.associazioniModelloNazione.length ?? 0;

    return associazioniAziendaSelectedCount > 0 || associazioniNazioneSelectedCount > 0;
  }

  public get fineValiditaMaxDate(): Date | null {
    const idProdotto = this.newEditSidebarForm?.formItem?.idCorp04002Prodotto;
    if (idProdotto == null) return null;
    return this.prodotti.find(a => a.id === idProdotto)?.fineValidita ?? null;
  }

  public get inizioValiditaMinDate(): Date | null {
    const idProdotto = this.newEditSidebarForm?.formItem?.idCorp04002Prodotto;
    if (idProdotto == null) return null;
    return this.prodotti.find(a => a.id === idProdotto)?.inizioValidita ?? null;
  }

  public getCodiceModello(modelloMatrice: IModelloMatrice): string {
    let codiceModello = `M${modelloMatrice.tipologiaMatrici?.sigla}-${modelloMatrice.id?.toString().padStart(5, '0')}`;
    if (modelloMatrice.tipologiaMatrici?.sigla === 'RC') codiceModello += `-${modelloMatrice.prodotto?.codice}`;

    return codiceModello;
  }

  public openMenu(menu: Menu, event: MouseEvent, modello: IModelloMatrice) {
    menu.toggle(event);

    const menuItems: MenuItem[] = [
      {
        label: this._translocoService.translate<string>('Modifica'),
        icon: 'pi pi-user-edit',
        iconStyle: {'color': '#E0A800'},
        command: () => this.onClickEdit(modello)
      },
      {
        label: this._translocoService.translate<string>('Clona'),
        icon: 'pi pi-clone',
        iconStyle: {'color': '#6A8FFA'},
        command: () => this.cloneModelloMatrice(modello)
      },
      {
        label: this._translocoService.translate<string>('Cancella'),
        icon: 'pi pi-trash',
        iconStyle: {'color': 'red'},
        command: () => this.onClickDelete(modello.id!)
      }
    ];

    if (modello.stato?.etichettaTraduzione?.chiaveUnivocaEtichetta == "Bozza") {
      menuItems.push({
        label: this._translocoService.translate<string>('Editor'),
        icon: 'pi pi-file-edit',
        iconStyle: {'color': '#05CBBC'},
        command: () => this._router.navigate([`/editor`] , {queryParams: {id: modello.id}})
      });
    }

    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: menuItems
      }
    ];
  }

  public loadAziende(): Observable<IAssociazioneModello[]> {
    return this._aziendeService.find().pipe(
      map(result => {
        return result.data.map(azienda => {
          return <IAssociazioneModello> { azienda, idCorp01002Azienda: azienda.id };
        });
      })
    );
  }

  public loadNazioni(): Observable<IAssociazioneModello[]> {
    return this._nazioniService.find().pipe(
      map(result => {
        return result.data.map(nazione => {
          return <IAssociazioneModello> { nazione, idCorp01003Nazione: nazione.id };
        });
      })
    );
  }

  public loadCentri(): Observable<IAssociazioneModello[]> {
    return this._centriService.find().pipe(
      map(result => {
        return result.data.map(centro => {
          return <IAssociazioneModello> { centro, idCorp01004Centro: centro.id };
        });
      })
    );
  }

  public loadModelliMatrici(event?: OnLoadItemsEvent<IModelloMatrice>): void {
    const relatedEntities: Array<string> = ['prodotto','tipologiaMatrici','stato','stato.etichettaTraduzione','tipologiaMatrici.etichettaTraduzione', 'associazioneModelli.azienda', 'associazioneModelli.nazione', 'associazioneModelli.centro'];
    this._modelliService.find(event?.filter, event?.pageNumber, event?.pageSize, event?.orderBy, relatedEntities).subscribe(result => {
      this.modelliMatrici = result.data;
      this.totalCount = result.count;
      });
  }

  public loadProdotti(): Observable<IFindResult<IProdotto>> {
    const today = new Date();
    return this._prodottiService.find().pipe(
      map(result => {
        result.data = result.data.filter(p => p.fineValidita == null || p.fineValidita.getTime() >= today.getTime());
        return result;
      })
    );
  }

  public loadTipologieMatrici(): Observable<IFindResult<ITipologiaMatrici>> {
    const relatedEntities: Array<keyof ITipologiaMatrici> = ['etichettaTraduzione'];
    return this._tipologieMatriciService.findAll(relatedEntities);
  }

  public loadStatiModelli(): Observable<IFindResult<IStatoModello>> {
		const relatedEntities: Array<keyof IStatoModello> = ['etichettaTraduzione'];
    return this._statiService.findAll(relatedEntities);
  }

  public onClickAdd(): void {
    forkJoin([
      this.loadProdotti(),
      this.loadAziende(),
      this.loadNazioni(),
      this.loadCentri()
    ]).subscribe(([rProdotti, rAziende, rNazioni, rCentri ]) => {
      this.prodotti = rProdotti.data;
      this.associazioneModelliAzienda = rAziende;
      this.associazioneModelliNazione = rNazioni;
      this.associazioneModelliCentro = rCentri;
      this.formMode = 'add';
      this.newEditSidebarForm.show();
    });
  }

  public onClickEdit(modelloMatrice: IModelloMatrice): void {
    forkJoin([
      this.loadAziende(),
      this.loadNazioni(),
      this.loadCentri(),
    ])
    .subscribe(([rAziende, rNazioni, rCentri ]) => {
      this.associazioneModelliAzienda = rAziende;
      this.associazioneModelliNazione = rNazioni;
      this.associazioneModelliCentro = rCentri;

      this.formMode = 'edit';
      const modelToEdit = new ModelloFormItem(modelloMatrice, [...this.associazioneModelliAzienda, ...this.associazioneModelliNazione, ...this.associazioneModelliCentro]);
      this.newEditSidebarForm.showOnEditMode(modelToEdit);
    });
  }

  public onFormSave(modelloMatrice: ModelloFormItem): void {
    if (this.formMode == null) return;

    const modelloMatriceToInsertOrUpdate = ModelloFormItem.ConvertToIModelloMatrice(modelloMatrice)
    this.formMode == 'add' ? this.insertModelloMatrice(modelloMatriceToInsertOrUpdate) : this.updateModelloMatrice(modelloMatriceToInsertOrUpdate);
  }

  private insertModelloMatrice(modelloMatrice: IModelloMatrice): void {
    const idStatoModello = this.statiModelli.find(s => s.etichettaTraduzione?.chiaveUnivocaEtichetta == "Bozza")?.id;

    if (idStatoModello == undefined) return;

    modelloMatrice.idCorp02011StatoModello = idStatoModello;
    this._modelliService.insert(modelloMatrice).subscribe(() => {
        this.newEditSidebarForm.hide();
        this.tableModelliMatrici.loadItems();
    });
  }

  private cloneModelloMatrice(modelloMatrice: IModelloMatrice): void {

    const clone = { ...modelloMatrice };

    clone.id = undefined;

    clone.associazioneModelli = clone.associazioneModelli.map(am => <IAssociazioneModello> {
      idCorp01002Azienda: am.idCorp01002Azienda,
      idCorp01003Nazione: am.idCorp01003Nazione,
      idCorp01004Centro: am.idCorp01004Centro,
    });

    clone.prodotto = undefined;
    clone.stato = undefined;
    clone.tipologiaMatrici = undefined;

    this._modelliService.insert(clone).subscribe(() => {
        this.tableModelliMatrici.loadItems();
    });
  }

  private updateModelloMatrice(modelloMatrice: IModelloMatrice): void {
    if (modelloMatrice.id == undefined) return;
    modelloMatrice.tipologiaMatrici = undefined;
    modelloMatrice.stato = undefined;
    modelloMatrice.prodotto = undefined;
    
    this._modelliService.update(modelloMatrice.id, modelloMatrice).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableModelliMatrici.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneModello'),
        accept: () => this.deleteModelloMatrice(id),
      }
    );
  }

  public deleteModelloMatrice(id:number) {
    this._modelliService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneModelloMatriceAvvenuta'));
      this.tableModelliMatrici.loadItems();
    });
  }

	getSeverity(chiave: string)
	{
		switch (chiave) {
			case 'ProntoPerLaValidazione':
					return 'warning';
			case 'Validato':
					return 'success';
			default:
					return 'danger';
		}
	}
}
