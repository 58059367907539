<cfm-page-header title="{{ 'ConfigurazioneProduttore' | transloco }}"></cfm-page-header>
<cfm-table
	#tableProduttori
  headerIcon="fa-solid fa-gears"
  title="{{ 'ListaProduttori' | transloco }}"
  [items]="produttori"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadProduttori($event)"
	(onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Sigla' | transloco }}" fieldName="sigla" [orderable]="true" [filterable]="true"></cfm-table-col>
	<cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità' | transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true" filterType="date">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>

  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

  <cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)" 
  (onChange)="onFormChange($event)"
  >

  <cfm-sidebar-form-item
    label="{{ 'Sigla' | transloco }}"
    key="sigla"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [maxDate]="inizioValiditaMaxDate"
  />
  <cfm-sidebar-form-item
    label="{{ 'FineValidità' | transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="fineValiditaMinDate"
    [disabled]="isFineValiditaDisabled"
  />
</cfm-sidebar-form>
