import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private callCounter = 0;
  public isLoading$ = new Subject<boolean>();

  startLoading() {
    if (this.callCounter == 0) this.isLoading$.next(true);
    this.callCounter++;
  }

  stopLoading() {
    this.callCounter--;
    if (this.callCounter == 0) this.isLoading$.next(false);
  }
  
}
