<cfm-page-header title="{{ 'ConfigurazioneModelloMatrice' | transloco }}"></cfm-page-header>
<cfm-table
  #tableModelliMatrici
  headerIcon="fa-solid fa-file"
  title="{{ 'ListaModelliMatrici' | transloco }}"
  [items]="modelliMatrici"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadModelliMatrici($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Codice' | transloco }}" fieldName="codice" [orderable]="true" [filterable]="true" [style]="{'width': '20rem'}">
    <ng-template #content let-item="item">
      {{ getCodiceModello(item) }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Prodotto' | transloco }}" fieldName="prodotto.denominazione" [orderable]="true" [filterable]="true" filterBy="idCorp04002Prodotto" [filterOptions]="prodotti" filterOptionLabel="denominazione" filterOptionValue="id" [style]="{'width': '20rem'}">
    <ng-template #content let-item="item">
    {{item.prodotto?.denominazione }}
    </ng-template>
  </cfm-table-col>

  <cfm-table-col header="{{ 'TipologiaMatrici' | transloco }}" fieldName="tipologiaMatrici.etichettaTraduzione.chiaveUnivocaEtichetta" [orderable]="true" [filterable]="true" filterBy="idCorp02002TipologiaMatrice" [filterOptions]="tipologieMatrici" filterOptionLabel="etichettaTraduzione.chiaveUnivocaEtichetta" filterOptionValue="id" [style]="{'width': '20rem'}">
    <ng-template #content let-item="item">
    {{item.tipologiaMatrici?.etichettaTraduzione?.chiaveUnivocaEtichetta | transloco}}
    </ng-template>
  </cfm-table-col>

  <!-- bisognera vedere di visualizzare il campo corretto --> <!-- Ho modificato il fieldname e cosi sembra funzionare, bisognera controllare perche non acceta statoModello nel filtro-->
  <cfm-table-col header="{{ 'StatoModello' | transloco }}" fieldName="stato.etichettaTraduzione.chiaveUnivocaEtichetta" [orderable]="true" [filterable]="true" filterBy="idCorp02011StatoModello" [filterOptions]="statiModelli" filterOptionLabel="etichettaTraduzione.chiaveUnivocaEtichetta" filterOptionValue="id" [style]="{'width': '20rem'}">
    <ng-template #content let-item="item">
			<p-tag [value]="item?.stato?.etichettaTraduzione?.chiaveUnivocaEtichetta| transloco" [severity]="getSeverity(item.stato?.etichettaTraduzione?.chiaveUnivocaEtichetta)"></p-tag>

    </ng-template>
  </cfm-table-col>

  <cfm-table-col header="{{'Azioni' | transloco }}" fieldName="id">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
  #newEditSidebarForm
  [formTitle]="formTitle | transloco"
  [validators]="formValidators"
  (onSave)="onFormSave($event)"
  >
  <cfm-sidebar-form-item
    label="{{ 'Codice' | transloco }}"
    key="codiceModello"
    type="text"
    [colWidth]="12"
    [disabled]="true"
  />
  <cfm-sidebar-form-item
    label="{{ 'Prodotto' | transloco }}"
    key="idCorp04002Prodotto"
    type="dropdown"
    dropdownOptionLabel="denominazione"
    dropdownOptionValue="id"
    [dropdownOptions]="prodotti"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'Azienda' | transloco }}"
    key="associazioniModelloAzienda"
    type="multiSelect"
    dropdownOptionLabel="azienda.sigla"
    [dropdownOptions]="associazioneModelliAzienda"
    [colWidth]="12"
    [disabled]="isAssociazioniAziendaDisabled"
  />
  <cfm-sidebar-form-item
    label="{{ 'Nazioni' | transloco }}"
    key="associazioniModelloNazione"
    type="multiSelect"
    dropdownOptionLabel="nazione.sigla"
    [dropdownOptions]="associazioneModelliNazione"
    [colWidth]="12"
    [disabled]="isAssociazioniNazioneDisabled"
    />
  <cfm-sidebar-form-item
    label="{{ 'Centri' | transloco }}"
    key="associazioniModelloCentro"
    type="multiSelect"
    dropdownOptionLabel="centro.sigla"
    [dropdownOptions]="associazioneModelliCentro"
    [colWidth]="12"
    [disabled]="isAssociazioniCentroDisabled"
    />
  <cfm-sidebar-form-item
    label="{{ 'TipologiaMatrici' | transloco }}"
    key="idCorp02002TipologiaMatrice"
    type="dropdown"
    dropdownOptionLabel="etichettaTraduzione.chiaveUnivocaEtichetta"
    dropdownOptionValue="id"
    [dropdownOptions]="tipologieMatrici"
    [colWidth]="12"
  />
  <!-- Manca il valore per la label (etichettaTraduzione) -->
</cfm-sidebar-form>
