<div class="layout-topbar">
  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="onMenuToggle()">
      <i class="pi pi-bars"></i>
  </button>

  <img src="assets/layout/images/themes/logo-Solgroup-white.png" style="width:80px;" />
  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="showProfileSidebar()">
      <i class="pi pi-ellipsis-v"></i>
  </button>
  
  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{ 'layout-topbar-menu-mobile-active': profileSidebarVisible }">
      <a class="layout-topbar-logo" [routerLink]="['/', 'dashboard']">
          <img src="assets/layout/images/themes/logo-SOLPOC.png" />
      </a>
      <div class="user-box">
        <h1>{{ this.fullnameCurrentUser }}</h1>
      </div>

      <app-dropdown-lingue class="dropdown-lingue"></app-dropdown-lingue>

      <!--logout-->
      <button class="p-link layout-topbar-button" (click)="onClickLogout()">
          <i class="pi pi-sign-out"></i>
          <span>Logout</span>
      </button>

  </div>






<!-- <div class="layout-topbar">
  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <img class="logoSolgroup" src="assets/layout/images/themes/logo-Solgroup-white.png" />
  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{ 'layout-topbar-menu-mobile-active':  profileSidebarVisible }">
    <a class="layout-topbar-logo" routerLink="/">
      <img src="assets/layout/images/themes/logo-SOLPOC.png" />
    </a>
    <div class="userBox">
      <h1 class="nameUser" for="Nome utente">{{ this.fullnameCurrentUser }}</h1>
    </div>

    <app-dropdown-lingue></app-dropdown-lingue>

    <button class="p-link layout-topbar-button" (click)="onClickLogout()">
      <i class="pi pi-sign-out"></i>
      <span>Logout</span>
    </button>
  </div>
</div> -->
