import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class CfmMessageService {
  constructor(private _translocoService: TranslocoService,private _messageService: MessageService, private _confirmationService:ConfirmationService) { }

  //confirm chiede come parametro un Confirm (parametri non obligatori)
  //qua passo come parametro due funzioni che mi aspetto che vengano fatte nel componente che li chiamerà
  public showConfirmDialog(messageConfirm: Confirmation){

    this._confirmationService.confirm({
      accept: messageConfirm.accept,
      reject: messageConfirm.reject,
      header: messageConfirm.header,
      message: messageConfirm.message,
      icon: messageConfirm.icon || 'pi pi-exclamation-triangle',
      acceptLabel: messageConfirm.acceptLabel || this._translocoService.translate<string>('Si'),
      rejectLabel: messageConfirm.rejectLabel || this._translocoService.translate<string>('No'),
      acceptIcon: messageConfirm.acceptIcon || "",
      rejectIcon: messageConfirm.rejectIcon || "",
      acceptButtonStyleClass: messageConfirm.acceptButtonStyleClass || "w-8rem",
      rejectButtonStyleClass: messageConfirm.rejectButtonStyleClass || "p-button-outlined w-8rem"
    })
  }

  public showErrorMessage(detail: string) {
    this._messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  public showWarningMessage(detail: string) {
    this._messageService.add({
      severity: 'warning',
      summary: 'Warning',
      detail: detail,
    });
  }

  public showInfoMessage(detail: string){
    this._messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: detail,
    });
  }

  public showSuccessMessage(detail: string) {
    this._messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }
}
