import { Injectable } from '@angular/core';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root',
})
export default class LocalStorageService {
  private readonly LOCALSTORAGE_TOKEN: string = 'token';
  private readonly LOCALSTORAGE_TOKEN_EXPIRATION: string = 'token_expiration';

  constructor() {}

  public get tokenExpiration(): Date {
    const tokenExpirationLocalStorageValue: string | null = localStorage.getItem(this.LOCALSTORAGE_TOKEN_EXPIRATION);
    if (!!tokenExpirationLocalStorageValue) {
      return new Date(tokenExpirationLocalStorageValue);
    }
    return new Date(-1);
  }

  public set tokenExpiration(tokenExpiration: Date) {
    localStorage.setItem(this.LOCALSTORAGE_TOKEN_EXPIRATION, tokenExpiration.toUTCString());
  }

  public get token(): string {
    const tokenLocalStorageValue: string | null = localStorage.getItem(this.LOCALSTORAGE_TOKEN);
    if (!!tokenLocalStorageValue) return tokenLocalStorageValue;
    return '';
  }

  public set token(token: string) {
    localStorage.setItem(this.LOCALSTORAGE_TOKEN, token);
  }
  
  public get fullnameCurrentUser(): string {
    if (this.token) {
      const decodedToken: any = jwtDecode(this.token);
      return decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || '';
    }
    return '';
  }
  
  public get roleCurrentUser(): string {
    if (this.token) {
      const decodedRoleToken: any = jwtDecode(this.token);
      return decodedRoleToken['Roles'] || '';
    }
    return '';
  }

  public clean() {
    localStorage.removeItem(this.LOCALSTORAGE_TOKEN);
    localStorage.removeItem(this.LOCALSTORAGE_TOKEN_EXPIRATION);
  }
}
