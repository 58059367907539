import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';

export interface IProduttore
{
  id : number;
  sigla : string;
	inizioValidita: Date;
  fineValidita: Date | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ProduttoriService extends BaseService<IProduttore> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/produttori-device-mobile', CountType.Yes, _httpClient);
  }
}
