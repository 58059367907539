import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingService } from '../../../services/loading.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner-loading',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './spinner-loading.component.html',
  styleUrl: './spinner-loading.component.scss'
})
export class SpinnerLoadingComponent implements OnInit {

  public visible: boolean = false;

  constructor(public _loadingService : LoadingService) {
  }

  ngOnInit(): void {
    this._loadingService.isLoading$.subscribe(isLoading => this.visible = isLoading);
  }
}
