import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IProdotto } from './prodotti.service';
import { ITipologiaMatrici } from './tipologie-matrici.service';
import { IStatoModello } from './stati-modelli.service';
import { IAzienda } from './aziende.service';
import { INazione } from './nazioni.service';
import { ICentro } from './centri.service';

export interface IModelloMatrice {
  id?: number;
  idCorp04002Prodotto: number;
  idCorp02002TipologiaMatrice: number;
  idCorp02011StatoModello: number;
  idCorp01002Azienda?: number;
  idCorp01003Nazione?: number;
  idCorp01004Centro?: number;

  associazioneModelli: IAssociazioneModello[];
  prodotto?: IProdotto;
  tipologiaMatrici?: ITipologiaMatrici;
  stato?: IStatoModello;
}

export interface IAssociazioneModello {
  id?: number;
  idCorp07001ModelloMatrice?: number;
  idCorp01002Azienda?: number;
  idCorp01003Nazione?: number;
  idCorp01004Centro?: number;

  azienda?: IAzienda | null;
  nazione?: INazione | null;
  centro?: ICentro | null;
}

@Injectable({
  providedIn: 'root'
})
export class ModelliMatriciService extends BaseService<IModelloMatrice> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/modelli-matrici', CountType.Yes, _httpClient);
  }
}
