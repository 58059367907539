import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from "primeng/dialog"; 
import { TableModule } from "primeng/table";
import { VersionService, IVersion } from '../../services/version.service';
import packageJson from '../../../../package.json';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-modal-version',
  standalone: true,
  imports: [ButtonModule, CommonModule, FormsModule, DialogModule, TableModule],
  templateUrl: './cfm-modal-version.component.html',
  styleUrl: './cfm-modal-version.component.scss'
})
export class ModalVersionComponent {
  public visible: boolean = false;
  public versions: IVersion[] = []; 
  public versionAngular = packageJson.version;

  constructor(private _versionService: VersionService, private _messageService: MessageService) { }

  public showDialog() {
    this._versionService.getVersions().subscribe(versions => {
      this.versions = versions; 
      this.visible = true; 
    }); 
  }  
}
