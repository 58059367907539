import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import LocalStorageService from './local-storage.service';
import { Router } from '@angular/router';

export interface IAutenticazioneRequest {
  username: string;
  password: string;
}

export interface IAutenticazioneResponse {
  jwtToken: IJwtToken;
}

export interface IJwtToken {
  validTo: Date;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  constructor(
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService,
    private router: Router
  ) {}

  public login(
    request: IAutenticazioneRequest
  ): Observable<IAutenticazioneResponse> {
    return this._httpClient.post<IAutenticazioneResponse>(
      `${environment.baseApi}/auth-api/auth/login`,
      request
    );
  }

  public logout(): void {
    this._localStorageService.clean();
    this.router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    const now = new Date();

    const token = this._localStorageService.token;
    const tokenExpiration = this._localStorageService.tokenExpiration;
    return !!token && tokenExpiration > now;
  }

  public get isSuperUser(): boolean {
    const roles: string[] = this._localStorageService.roleCurrentUser.split(',');
    return roles.includes('Superuser');
  }
}
