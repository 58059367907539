import { DatePipe } from '@angular/common';
import { Component, ViewChild, OnInit } from '@angular/core';
import { IUtente, IUtenteProfilo, UtentiService } from '../../../services/utenti.service';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { ButtonModule } from 'primeng/button';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { AziendeService, IAzienda } from '../../../services/aziende.service';
import { INazione, NazioniService } from '../../../services/nazioni.service';
import SistemiInformativiService, { ISistemaInformativo } from '../../../services/sistemi-informativi.service';
import { IFindResult } from '../../../services/base.service';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable, forkJoin, map, of } from 'rxjs';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { AbstractControl, FormsModule, ValidationErrors, Validators} from '@angular/forms';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { IStabilimento, StabilimentiService } from '../../../services/stabilimenti.service';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { IProfilo, ProfiloService } from '../../../services/profilo.service';
import LocalStorageService from '../../../services/local-storage.service';
import AuthService from '../../../services/auth.service';

class UtenteFormItem {
  public id: number;
  public userid: string;
  public idCorp01005SistemaInformativoGestionale: number;
  public anagraficaUtente: string;
  public idCorp01002Azienda: number;
  public idCorp01003Nazione: number | null;
  public idCorp01004Centro: number | null;
  public profili: IProfilo[];
  public usernameFirmaElettronica: string;
  public inizioValidita: Date;
  public fineValidita: Date | undefined;
  public azienda:IAzienda;
  public nazione: INazione | null;
  public sistemaInformativoGestionale: ISistemaInformativo | null;
  public centro: ISistemaInformativo | null;

  constructor(init: IUtente, profili: IProfilo[]) {
    this.id = init.id;
    this.userid = init.userid;
    this.idCorp01005SistemaInformativoGestionale = init.idCorp01005SistemaInformativoGestionale;
    this.anagraficaUtente = init.anagraficaUtente;
    this.idCorp01002Azienda = init.idCorp01002Azienda;
    this.idCorp01003Nazione = init.idCorp01003Nazione;
    this.idCorp01004Centro = init.idCorp01004Centro;
    this.profili = profili.filter(ap => init.utenteProfilo?.map(iap => iap.idCorp05003Profilo).includes(ap.id));
    this.usernameFirmaElettronica = init.usernameFirmaElettronica;
    this.inizioValidita = init.inizioValidita;
    this.fineValidita = init.fineValidita;
    this.azienda = init.azienda;
    this.nazione = init.nazione;
    this.sistemaInformativoGestionale = init.sistemaInformativoGestionale;
    this.centro = init.centro;
  }

  public static ConvertToIProdotto(utente: UtenteFormItem): IUtente {
    const utentiProfilo: IUtenteProfilo[] = utente.profili.map(p => <IUtenteProfilo> {
      idCorp05001Utente: utente.id,
      idCorp05003Profilo: p.id
    });

    return <IUtente>{
      id: utente.id,
      userid: utente.userid,
      idCorp01005SistemaInformativoGestionale: utente.idCorp01005SistemaInformativoGestionale,
      anagraficaUtente: utente.anagraficaUtente,
      idCorp01002Azienda: utente.idCorp01002Azienda,
      idCorp01003Nazione: utente.idCorp01003Nazione,
      idCorp01004Centro: utente.idCorp01004Centro,
      utenteProfilo: utentiProfilo,
      usernameFirmaElettronica: utente.usernameFirmaElettronica,
      inizioValidita: utente.inizioValidita,
      fineValidita: utente.fineValidita,
      nazione: utente.nazione,
      sistemaInformativoGestionale: utente.sistemaInformativoGestionale,
      centro: utente.centro,
    };
  }
}

@Component({
  selector: 'app-utenti',
  standalone: true,
  imports: [
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    ButtonModule,
    FormsModule,
    TableModule,
    CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
    MultiSelectModule,
    TranslocoModule,
    MenuModule
  ],
  templateUrl: './utenti.component.html',
  styleUrl: './utenti.component.scss'
})
export class UtentiComponent {

  @ViewChild('tableUtenti') tableUtenti!: CfmTableComponent<IUtente>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<UtenteFormItem>;

  public utenti: IUtente[] = [];
  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof UtenteFormItem, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento / modifica azienda
  public aziende: IAzienda[] = [];
  public nazioni: INazione[] = [];
  public centri: IStabilimento[] = [];
  public sistemaInformativoGestionali: ISistemaInformativo[] = [];
  public nazioniSidebar: INazione[] = [];
  public centriSidebar: IStabilimento[] = [];
  public items: MenuItem[] = [];
  public profili: IProfilo[] = [];

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoUtente';
    else return 'ModificaUtente';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }
  
  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  
  constructor(private _translocoService: TranslocoService, private _centroService: StabilimentiService,private _sistemiInformativiService: SistemiInformativiService, private _nazioniService: NazioniService, private _aziendeService: AziendeService, private _utentiService: UtentiService, private _cfmMessageService: CfmMessageService, private _profiliService: ProfiloService, private _localStorageService: LocalStorageService, public _authService: AuthService) {
    this.formValidators.set('idCorp01002Azienda', [Validators.required]);
    this.formValidators.set('idCorp01005SistemaInformativoGestionale', [Validators.required]);
    this.formValidators.set('anagraficaUtente', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('usernameFirmaElettronica', [Validators.required, Validators.maxLength(50)]);
    this.formValidators.set('userid', [Validators.required, Validators.maxLength(50)]);
    this.formValidators.set('inizioValidita', [Validators.required]);

    this.loadAziende().subscribe(result => this.aziende = result.data);
    this.loadNazioni().subscribe(result => this.nazioni = result.data);
    this.loadCentri().subscribe(result => this.centri = result.data);
    this.loadSistemaInformativi().subscribe(result => this.sistemaInformativoGestionali = result.data);
  }

  openMenu(menu: Menu, event: MouseEvent, utente: IUtente) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(utente)                
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(utente),
                  command: () => this.onClickDelete(utente.id)
                }
            ]
        }
    ];
  }

  public loadUtenti(event: OnLoadItemsEvent<IUtente>): void {
    const relatedEntities: Array<string> = ['azienda', 'nazione', 'centro', 'sistemaInformativoGestionale', 'utenteProfilo.profilo', 'utenteProfilo.profilo.etichettaTraduzione','utenteProfilo.profilo.etichettaTraduzione.chiaveUnivocaEtichetta', 'utenteProfilo'];
    this._utentiService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy, relatedEntities).subscribe(result => {
      this.utenti = result.data;
      this.totalCount = result.count;
    });
  }

  public onChange(event: OnFormItemChangeEvent<UtenteFormItem>) {
    if (event.ctrlName == 'idCorp01002Azienda') {
      this.loadNazioniSidebar(event.newValue).subscribe(res => {
        this.nazioniSidebar = res;
        this.centriSidebar = [];
      });
    }
    
    if (event.ctrlName == 'idCorp01003Nazione') {
      this.loadCentriSidebar(event.newValue).subscribe(res => {
        this.centriSidebar = res;
      });
    }
  }
  
  private loadNazioniSidebar(idAzienda: number) {
    return this._nazioniService.find(<any>{ 'idCorp01002Azienda[equals]': idAzienda }).pipe(map(result => result.data));
  }

  private loadCentriSidebar(idNazione: number) {
    return this._centroService.find(<any>{ 'idCorp01003Nazione[equals]': idNazione }).pipe(map(result => result.data));
  }

  public loadAziende(): Observable<IFindResult<IAzienda>> {
    return this._aziendeService.find();
  }

  public loadNazioni(): Observable<IFindResult<INazione>> {
    return this._nazioniService.find();
  }

  public loadCentri(): Observable<IFindResult<IStabilimento>> {
    return this._centroService.find();
  }

  public loadSistemaInformativi(): Observable<IFindResult<ISistemaInformativo>> {
    return this._sistemiInformativiService.find();
  }

  public loadProfili(): Observable<IFindResult<IProfilo>> {
    return this._profiliService.findAll();
  }

  public onClickAdd(): void {
    forkJoin([
      this.loadAziende(),
      this.loadSistemaInformativi(),
      this.loadProfili(),
    ]).subscribe(([rAziende, rSistemiInformativi, rProfili]) => {
        this.aziende = rAziende.data;
        this.sistemaInformativoGestionali = rSistemiInformativi.data;
        this.profili = rProfili.data;
        this.formMode = 'add';
        this.newEditSidebarForm.show();
    });
  }


  public onClickEdit(utente: IUtente): void {
    forkJoin([
      this.loadAziende(),
      this.loadSistemaInformativi(),
      this.loadNazioniSidebar(utente.idCorp01002Azienda),
      this.loadProfili(),
      utente.idCorp01003Nazione ? this.loadCentriSidebar(utente.idCorp01003Nazione) : of([])
    ]).subscribe(([rAziende, rSistemiInformativi, rNazioniSidebar, rProfili]) => {
      this.aziende = rAziende.data;
      this.sistemaInformativoGestionali = rSistemiInformativi.data;
      this.nazioniSidebar = rNazioniSidebar;
      this.profili = rProfili.data;

      this.formMode = 'edit';
      const utenteToEdit = new UtenteFormItem(utente, this.profili);
      this.newEditSidebarForm.showOnEditMode(utenteToEdit);
    });
  }

  public onFormSave(utente: UtenteFormItem) {
    if (this.formMode == null ) return;
    const utenteToInsertOrUpdate = UtenteFormItem.ConvertToIProdotto(utente);

    this.formMode === 'add' ? this.insertUtente(utenteToInsertOrUpdate) : this.updateUtente(utenteToInsertOrUpdate);
  }

  private insertUtente(utente: IUtente): void {
    this._utentiService.insert(utente).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableUtenti.loadItems();
    });
  }

  private updateUtente(utente: IUtente): void {
    utente.nazione = null
    utente.centro = null;
    utente.sistemaInformativoGestionale = null;

    this._utentiService.update(utente.id, utente).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableUtenti.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
        {
          header: this._translocoService.translate<string>('Attenzione'),
          message: this._translocoService.translate<string>('ConfermaCancellazioneUtente'),
          accept: () => this.deleteUtente(id),
        },
    );
  }

  public deleteUtente(id: number) {
    this._utentiService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneUtenteAvvenuta'));
      this.tableUtenti.loadItems();
    });
  }

  public isDeleteDisabled(utente: IUtente) {
    if (utente.inizioValidita < new Date() && utente.fineValidita === null) return false;    
    return utente.inizioValidita > new Date() || (utente.fineValidita !== undefined && utente.fineValidita < new Date());
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<UtenteFormItem>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}

