import { HttpErrorResponse, HttpEvent, HttpHeaders, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';
import AuthService from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import LocalStorageService from '../services/local-storage.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);
  const authService = inject(AuthService);
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const loadingService = inject(LoadingService);

  let headers = new HttpHeaders({
    //'Content-Type': 'application/json',
    withCredentials: 'true',
  });

  const isAuthenticated = authService.isAuthenticated();
  if (isAuthenticated) {
    headers = headers.append('Authorization', `Bearer ${localStorageService.token}`);
  }

  let apiReq = req.clone({
    url: `${req.url}`,
    withCredentials: true,
    headers: headers,
  });

  const routerSnapshot = router.routerState.snapshot;
  let currentUrl = routerSnapshot.url;

  loadingService.startLoading();
  return next(apiReq).pipe(
      tap({
        next: (httpEvent: HttpEvent<any>) => {
          // skip request
          if (httpEvent.type == 0) return;

          if (!(httpEvent instanceof HttpResponse)) return;

          //   if (httpEvent.headers.has("X-NEW-TOKEN")) {
          //       const xNewToken = httpEvent.headers.get("X-NEW-TOKEN");
          //       const tokenInfo = new JwtTokenModel(JSON.parse(xNewToken));
          //       this._authService.setAuthenticationDetail(tokenInfo.value, tokenInfo.validTo);
          //   }

          loadingService.stopLoading();
          handleDates(httpEvent.body);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status !== 401 || currentUrl == '/login') {
            let message: string = `Unexpected error occurred. More detail in console`;

            if (error.error?.description) message = error.error.description;
            messageService.add({ severity: 'error', summary: 'Error', detail: message });

            console.error(`Unexpected error occurred during request: '${req.url}'`, error);
          } else {
            // unauthorized
            if (currentUrl == '/login') return;

            authService.logout();
            router.navigate(['/login'], {
              queryParams: { returnUrl: `${currentUrl}` },
            });
          }
          loadingService.stopLoading();
        },
        complete: () => {},
      })
    );
};

export const isoDateFormat = /\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?)?/;

export function isIsoDateString(value: any): boolean {
  const r = value && typeof value === 'string' && isoDateFormat.test(value);
  return r;
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];

    if (isIsoDateString(value)) {
      const convertedDate = new Date(value.slice(-1) == 'Z' ? value : value + 'Z');
      
      // Verifica se la data convertita è valida, in caso di Invalid Date 'getTime()' restituisce NaN.
      if (!isNaN(convertedDate.getTime())) body[key] = convertedDate;
    }
    else if (typeof value === 'object') handleDates(value);
  }
}
