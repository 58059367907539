import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { StepperModule } from 'primeng/stepper';

@Component({
  selector: 'app-cfm-step-editor',
  standalone: true,
  imports: [ButtonModule, StepperModule],
  templateUrl: './cfm-step-editor.component.html',
  styleUrl: './cfm-step-editor.component.scss'
})
export class CfmStepEditorComponent {

  @Input({ required: true }) header: string = '';
  @Input({ required: true }) content: string = '';
  @Input({ required: true }) label: string = '';
  @Output() buttonClicked = new EventEmitter<void>();

}
