<p-dropdown
  [options]="lingueDisponibili"
  [(ngModel)]="linguaSelezionata"
  (onChange)="onChangeLinguaSelezionata($event.value)"
>
  <ng-template let-lingua pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <img src="assets/layout/images/flag_placeholder.png" [class]="'flag flag-' + lingua.code.toLowerCase()" style="width: 18px" />
      <div>{{ lingua.name }}</div>
    </div>
  </ng-template>
  <ng-template let-lingua pTemplate="item">    
    <div class="flex align-items-center gap-2">
      <img src="assets/layout/images/flag_placeholder.png" [class]="'flag flag-' + lingua.code.toLowerCase()" style="width: 18px" />
      <div>{{ lingua.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
