import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { ISistemaInformativo } from './sistemi-informativi.service';
import { IAzienda } from './aziende.service';
import { INazione } from './nazioni.service';
import { IStabilimento } from './stabilimenti.service';
import { IProfilo } from './profilo.service';

export interface IUtente {
  id: number;
  userid: string;
  idCorp01005SistemaInformativoGestionale: number;
  anagraficaUtente: string;
  idCorp01002Azienda: number;
  idCorp01003Nazione: number | null;
  idCorp01004Centro: number | null;
  utenteProfilo: IUtenteProfilo[];
  usernameFirmaElettronica: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  azienda: IAzienda;
  centro: IStabilimento | null;
  nazione: INazione | null;
  sistemaInformativoGestionale: ISistemaInformativo | null;
}

export interface IUtenteProfilo {
  id?: number;
  idCorp05001Utente?: number;
  idCorp05003Profilo?: number;

  utente?: IUtente;
  profilo?: IProfilo | null;
}

@Injectable({
  providedIn: 'root'
})
export class UtentiService extends BaseService<IUtente>{
  constructor(_httpClient: HttpClient) {
    super('service-db-api/utenti', CountType.Yes, _httpClient);
  }
}
