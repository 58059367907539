import { Component } from '@angular/core';
import { TranslocoRootModule } from '../../transloco-root.module';
import { CfmDashboardCardComponent } from '../../components/cfm-dashboard-card/cfm-dashboard-card.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, TranslocoRootModule, CfmDashboardCardComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  handleCardClick(): void {
  }
}
