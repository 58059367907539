import { Injectable } from '@angular/core';
import { IEtichettaTraduzione } from './etichetta-traduzione.service';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';

export interface ITipoImpiantoRiempimento {
  id: number;
  idEtichettaTraduzione: number;
  siglaTipologiaImpianto: string;

  etichettaTraduzione?: IEtichettaTraduzione;
}

@Injectable({
  providedIn: 'root'
})
export class TipiImpiantiRiempimentoService extends BaseService<ITipoImpiantoRiempimento> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/tipi-impianti-riempimento', CountType.Yes, _httpClient);
  }
}
