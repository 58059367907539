import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, CountType } from './base.service';
import { IImmagine } from './immagini.service';

export interface IAzienda {
  id: number;
  idCorp01007Immagine: number;
  ragioneSociale: string;
  sigla: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  logo: IImmagine
}

@Injectable({
  providedIn: 'root',
})
export class AziendeService extends BaseService<IAzienda> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/aziende', CountType.Yes, _httpClient);
  }
}
