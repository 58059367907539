import { Component,  ContentChildren, EventEmitter, Input,  Output, QueryList} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {  TableLazyLoadEvent, TableModule } from 'primeng/table';
import { SortInfo } from '../../services/base.service';
import { CommonModule } from '@angular/common';
import { CfmTableColComponent } from '../cfm-table-col/cfm-table-col.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslocoModule } from '@ngneat/transloco';
import { CalendarModule } from 'primeng/calendar';
import { SplitPipe } from "../../pipe/split.pipe";

export type OnLoadItemsEvent<T> = { filter: Partial<T>, pageNumber: number, pageSize: number, orderBy?: SortInfo };

@Component({
    selector: 'cfm-table',
    standalone: true,
    templateUrl: './cfm-table.component.html',
    styleUrl: './cfm-table.component.scss',
    imports: [CommonModule, ButtonModule, TableModule, MultiSelectModule, FormsModule, TranslocoModule, CalendarModule, SplitPipe]
})
export class CfmTableComponent<T> {
  @ContentChildren(CfmTableColComponent) columnComponents!: QueryList<CfmTableColComponent>;

  @Input({ required: true }) headerIcon!: string;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) items!: T[];
  @Input({ required: true }) totalItemsCount!: number;
  @Input() showAddButton: boolean = true;

  @Output() public onLoadItems: EventEmitter<OnLoadItemsEvent<T>> = new EventEmitter();
  @Output() public onAdd: EventEmitter<void> = new EventEmitter();

  public pageSize = 5;
  public indexFirstElement = 0;
  public rowsPerPageOptions: number[] = [5, 10, 25, 50, 100];

  public get showRowFilter(): boolean {
    return this.columnComponents.some(cc => cc.filterable == true);
  }

  public loadItems(event?: TableLazyLoadEvent): void {
    const filter: any = {};

    if (event?.filters) {

      // Verifica di tutti i filtri applicati in tabella
      for (const propName in event.filters) {
        // Se il filtro non è definito viene ignorato
        const filterItem = event.filters[propName];
        if (filterItem === undefined) { continue; }


        // Recupero del valore del filtro e valorizzazione dell'oggetto interno filter
        if (!Array.isArray(filterItem) && filterItem.value) {
          let matchMode = filterItem.matchMode;

          // Gestione match mode per le date
          if (matchMode == 'dateIs') matchMode = 'equals';
          if (matchMode == 'dateIsNot') matchMode = 'notEquals';
          if (matchMode == 'dateBefore') matchMode = 'lte';
          if (matchMode == 'dateAfter') matchMode = 'gte';

          const filterKey = `${propName}[${matchMode ?? 'equals'}]`;

          // Nel caso in cui la lunghezza della stringa o dell'array sia 0 il filtro non viene considerato.
          if (filterItem.value.length != 0) {
            filter[filterKey as any] = filterItem.value;
          }
        }
      }
    }

    // Aggiornamento page size
    let resetIndexFirstElement = false;
    if (event?.rows && this.pageSize != event.rows) {
      this.pageSize = event.rows;
      // Resetta la pagina corrente a 1 ogni volta che cambia il pageSize
      resetIndexFirstElement = true;
    }

    // Aggiornamento index first Element
    this.indexFirstElement = resetIndexFirstElement ? 0 : event?.first ?? 0;

    // Recupero page number corrente
    const pageNumber = (this.indexFirstElement + this.pageSize) / this.pageSize;

    // Recupero informazioni relative all'ordinamento
    const orderBy = event ? new SortInfo(event) : undefined;

    this.onLoadItems.emit({ filter, pageNumber, pageSize: this.pageSize, orderBy });
  }

  public onClickAdd(): void {
    this.onAdd.emit();
  }

  public onSave() {
    this.loadItems();
  }
}
