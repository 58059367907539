import { provideTransloco, Translation, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { APP_INITIALIZER, Injectable, isDevMode, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { TranslocoInitService } from './services/transloco-init.service'; 
import { TranslateModule } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`${environment.baseApi}/service-db-api/lingue/${lang}/cfm.portale/etichette`);
  }  

  initTransloco(translocoInitService: TranslocoInitService) {
    return () => translocoInitService.loadTranslations();
  }  
}

@NgModule({
  imports: [
    TranslateModule.forRoot(),
  ],
  exports: [ TranslocoModule ],
  providers: [
    TranslocoHttpLoader,
    TranslocoInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: TranslocoHttpLoader.prototype.initTransloco,
      deps: [TranslocoInitService],
      multi: true, 
    },
    provideTransloco({
      config: {
        defaultLang: 'it',
        reRenderOnLangChange: true,
        prodMode: isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
  ],
})
export class TranslocoRootModule {}

