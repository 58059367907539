import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { ILingua } from './lingue.service';
import { IAzienda } from './aziende.service';
import { IImmagine } from './immagini.service';
import { ISistemaInformativo } from './sistemi-informativi.service';

export interface INazione {
  id: number;
  idCorp01007Immagine: number;
  idCorp01005SistemaInformativoGestionale: number;
  idCorp01002Azienda: number;
  codiceNazioneLotto: string;
  sigla: string;
  idCorp03001Lingua: number;
  inizioValidita: Date;
  fineValidita: Date | undefined;

  logo:IImmagine
  sistemaInformativoGestionale: ISistemaInformativo;
  azienda: IAzienda;
  lingua: ILingua;
}

@Injectable({
  providedIn: 'root'
})
export class NazioniService extends BaseService<INazione> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/nazioni', CountType.Yes, _httpClient);
  }
}
