import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatterPipe',
  standalone: true
})
export class DateFormatterPipePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    const date = new Date(value);
    // Rimuovo tutto ciò che viene dopo 'GMT'
    return date.toString().replace(/GMT.*/g, '').trim();
  }
}
