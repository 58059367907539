import { CommonModule, DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { NazioniService } from '../../../services/nazioni.service';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { ButtonModule } from 'primeng/button';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { DropdownModule } from 'primeng/dropdown';
import { Observable, forkJoin, map } from 'rxjs';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { IAssociazioneProdotto, IProdotto, IUtenteProdotto, ProdottiService } from '../../../services/prodotti.service';
import { AziendeService } from '../../../services/aziende.service';
import { CentroService, ICentro } from '../../../services/centri.service';
import { UtentiService } from '../../../services/utenti.service';
import AuthService from '../../../services/auth.service';


class ProdottoFormItem {
  public id: number;
  public denominazione: string;
  public codice: string;
  public inizioValidita: Date;
  public fineValidita: Date | undefined;
  public associazioniProdottoAzienda: IAssociazioneProdotto[];
  public associazioniProdottoNazione: IAssociazioneProdotto[];
  public associazioniProdottoCentro: IAssociazioneProdotto[];
  public utentiAssegnatiProdotto: IUtenteProdotto[];
  /**
   * Costruttore della classe ProdottoFormItem.
   * @param init - Dati iniziali per il prodotto.
   * @param associazioniProdotto - Associazioni del prodotto con altre entità.
   */
  constructor(init: IProdotto, associazioniProdotto: IAssociazioneProdotto[], utentiProdotto: IUtenteProdotto[]) {
    this.id = init.id;
    this.denominazione = init.denominazione;
    this.codice = init.codice;
    this.associazioniProdottoAzienda = associazioniProdotto.filter(ap => init.associazioniProdotto.map(iap => iap.idCorp1002Azienda).includes(ap.idCorp1002Azienda));
    this.associazioniProdottoNazione = associazioniProdotto.filter(ap => init.associazioniProdotto.map(iap => iap.idCorp1003Nazione).includes(ap.idCorp1003Nazione));
    this.associazioniProdottoCentro = associazioniProdotto.filter(ap => init.associazioniProdotto.map(iap => iap.idCorp1004Centro).includes(ap.idCorp1004Centro));
    this.utentiAssegnatiProdotto = utentiProdotto.filter(up => init.prodottoUtente?.map(ua => ua.idCorp05001Utente).includes(up.idCorp05001Utente));
    this.inizioValidita = init.inizioValidita;
    this.fineValidita = init.fineValidita;
  }

  /**
   * Metodo statico per convertire ProdottoFormItem in un IProdotto.
   * @param prodotto - Il ProdottoFormItem da convertire.
   * @returns Il IProdotto convertito.
   */
  public static ConvertToIProdotto(prodotto: ProdottoFormItem): IProdotto {
    const associazioniProdotto: IAssociazioneProdotto[] = [];
    const utentiProdotto: IUtenteProdotto[] = [];

    associazioniProdotto.push(...prodotto.associazioniProdottoAzienda?.map(item => <IAssociazioneProdotto>({ idCorp1002Azienda: item.idCorp1002Azienda })) || []);
    associazioniProdotto.push(...prodotto.associazioniProdottoNazione?.map(item => <IAssociazioneProdotto>({ idCorp1003Nazione: item.idCorp1003Nazione })) || []);
    associazioniProdotto.push(...prodotto.associazioniProdottoCentro?.map(item => <IAssociazioneProdotto>({ idCorp1004Centro: item.idCorp1004Centro })) || []);
    utentiProdotto.push(...prodotto.utentiAssegnatiProdotto?.map(item => <IUtenteProdotto>({idCorp05001Utente: item.idCorp05001Utente})) || []);

    

    return <IProdotto>{
      id: prodotto.id,
      denominazione: prodotto.denominazione,
      codice: prodotto.codice,
      associazioniProdotto: associazioniProdotto,
      prodottoUtente: utentiProdotto,
      inizioValidita: prodotto.inizioValidita,
      fineValidita: prodotto.fineValidita,
    };
  }

}

@Component({
  selector: 'app-nazioni',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    CfmSidebarFormComponent,
    TranslocoModule,
    CfmSidebarFormItemComponent,
    ButtonModule,
    FormsModule,
    DropdownModule,
    CommonModule,
    MenuModule
  ],
  templateUrl: './prodotti.component.html',
  styleUrl: './prodotti.component.scss',
})
export class ProdottiComponent {

  @ViewChild('tableProdotti') tableProdotti!: CfmTableComponent<IProdotto>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<ProdottoFormItem>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof ProdottoFormItem, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento/modifica azienda
  public prodotti: IProdotto[] = [];
  public associazioniProdottoAzienda: IAssociazioneProdotto[] = [];
  public associazioniProdottoNazione: IAssociazioneProdotto[] = [];
  public associazioniProdottoCentro: IAssociazioneProdotto[] = [];
  public utenteProdotto: IUtenteProdotto[] = [];
  public tempUtenteProdotto: IUtenteProdotto[] = [];
  public items: MenuItem[] = [];

  constructor(
    private _translocoService: TranslocoService,
    private _prodottiService: ProdottiService, 
    private _aziendeService: AziendeService, 
    private _nazioniService: NazioniService, 
    private _centriService: CentroService,  
    private _cfmMessageService:CfmMessageService,
    private _utentiService: UtentiService,
    public _authService: AuthService
  ) {
    this.formValidators.set('denominazione', [Validators.required, Validators.maxLength(256)]);
    this.formValidators.set('codice', [Validators.required, Validators.maxLength(256)]);
    this.formValidators.set('inizioValidita', [Validators.required]);
    this.loadAziende().subscribe(result => this.associazioniProdottoAzienda = result);
    this.loadNazioni().subscribe(result => this.associazioniProdottoNazione = result);
    this.loadCentri().subscribe(result => this.associazioniProdottoCentro = result);
    this.loadUtenti().subscribe(result => this.utenteProdotto = result);
  }

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoProdotto';
    else return 'ModificaProdotto';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }  

  // La combobox dove selezionare le associazioni dei prodotti alle aziende va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni prodotto-nazione e prodotto-centro non è stato selezionato alcun valore.
  public get isAssociazioniAziendaDisabled(): boolean {
    const associazioniNazioneSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoNazione.length ?? 0;
    const associazioniCentroSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoCentro.length ?? 0;

    return associazioniNazioneSelectedCount > 0 || associazioniCentroSelectedCount > 0;
  }

  // La combobox dove selezionare le associazioni dei prodotti alle nazioni va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni prodotto-azienda e prodotto-centro non è stato selezionato alcun valore.
  public get isAssociazioniNazioneDisabled(): boolean {
    const associazioniAziendaSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoAzienda.length ?? 0;
    const associazioniCentroSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoCentro.length ?? 0;

    return associazioniAziendaSelectedCount > 0 || associazioniCentroSelectedCount > 0;
  }

  // La combobox dove selezionare le associazioni dei prodotti ai centri va abilitata solo nel caso in cui
  // per le combobox relative alle assocazioni prodotto-azienda e prodotto-nazione non è stato selezionato alcun valore.
  public get isAssociazioniCentroDisabled(): boolean {
    const associazioniAziendaSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoAzienda.length ?? 0;
    const associazioniNazioneSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoNazione.length ?? 0;

    return associazioniAziendaSelectedCount > 0 || associazioniNazioneSelectedCount > 0;
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isUserMultiSelectDisabled(): boolean {
    const associazioniAziendaSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoAzienda.length ?? 0;
    const associazioniNazioneSelectedCount = this.newEditSidebarForm?.formItem?.associazioniProdottoNazione.length ?? 0;
    const associazioniStabilimentoSelectCount = this.newEditSidebarForm?.formItem?.associazioniProdottoCentro.length ?? 0;

    return associazioniAziendaSelectedCount > 0 || associazioniNazioneSelectedCount > 0 || associazioniStabilimentoSelectCount > 0;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  openMenu(menu: Menu, event: MouseEvent, prodotto: IProdotto) {
    menu.toggle(event);

    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(prodotto)
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(prodotto),
                  command: () => this.onClickDelete(prodotto.id)
                }
            ]
        }
    ];
  }

  public loadAziende(): Observable<IAssociazioneProdotto[]> {
    return this._aziendeService.find().pipe(
      map(result => {
        return result.data.map(azienda => {
          return <IAssociazioneProdotto> { azienda, idCorp1002Azienda: azienda.id };
        });
      })
    );
  }

  public loadNazioni(): Observable<IAssociazioneProdotto[]> {
    return this._nazioniService.find().pipe(
      map(result => {
        return result.data.map(nazione => {
          return <IAssociazioneProdotto> { nazione, idCorp1003Nazione: nazione.id };
        });
      })
    );
  }

  public loadCentri(): Observable<IAssociazioneProdotto[]> {
    return this._centriService.find().pipe(
      map(result => {
        return result.data.map(centro => {
          return <IAssociazioneProdotto> { centro, idCorp1004Centro: centro.id };
        });
      })
    );
  }

  public loadUtenti(): Observable<IUtenteProdotto[]>{
    return this._utentiService.find().pipe(
      map(result => {
        return result.data.map(utente => {
          return <IUtenteProdotto> { utente, idCorp05001Utente: utente.id };
        })
        // .filter(x => x.utente?.centro?.id == )
      })
    )
  }

  public loadProdotti(event: OnLoadItemsEvent<IProdotto>) : void {
    const relatedEntities: Array<string> = ['associazioniProdotto.azienda', 'associazioniProdotto.nazione', 'associazioniProdotto.centro', 'prodottoUtente'];
    this._prodottiService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy, relatedEntities).subscribe(result => {
    this.prodotti = result.data;
    this.totalCount = result.count;
  });
 }

  public onClickAdd(): void {
    forkJoin([
      this.loadAziende(),
      this.loadNazioni(),
      this.loadCentri(),
      this.loadUtenti()
    ]).subscribe(([rAziende, rNazioni, rCentri, rUtenti]) => {
        this.associazioniProdottoAzienda = rAziende;
        this.associazioniProdottoNazione = rNazioni;
        this.associazioniProdottoCentro = rCentri;
        this.utenteProdotto = rUtenti;
        this.formMode = 'add';
        this.newEditSidebarForm.show();
    });
  }

  /**
 * Crea una nuova istanza di ProdottoFormItem.
 * @param prodotto - I dati iniziali per il prodotto.
 * @param associazioniProdotto - Le associazioni del prodotto con altre entità.
 * @returns Una nuova istanza di ProdottoFormItem.
 */
  public onClickEdit(prodotto: IProdotto): void {
    forkJoin([
      this.loadAziende(),
      this.loadNazioni(),
      this.loadCentri(),
      this.loadUtenti()
    ])
    .subscribe(([rAziende, rNazioni, rCentri, rUtenti ]) => {
      this.associazioniProdottoAzienda = rAziende;
      this.associazioniProdottoNazione = rNazioni;
      this.associazioniProdottoCentro = rCentri;
      this.utenteProdotto = rUtenti;
      this.formMode = 'edit';

      const productToEdit = new ProdottoFormItem(prodotto, [...this.associazioniProdottoAzienda, ...this.associazioniProdottoNazione, ...this.associazioniProdottoCentro], this.utenteProdotto);
      this.newEditSidebarForm.showOnEditMode(productToEdit);
    });
  }

  public onFormSave(prodotto: ProdottoFormItem) {
    if (this.formMode == null) return;

    const prodottoToInsertOrUpdate = ProdottoFormItem.ConvertToIProdotto(prodotto);

    this.formMode === 'add'? this.insertProdotto(prodottoToInsertOrUpdate) : this.updateProdotto(prodottoToInsertOrUpdate);
  }

  private insertProdotto(prodotto: IProdotto): void {
    this._prodottiService.insert(prodotto).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableProdotti.loadItems();
    });
  }

  private updateProdotto(prodotto: IProdotto): void {
    this._prodottiService.update(prodotto.id, prodotto).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableProdotti.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneProdotto'),
        accept: () => this.deleteProdotto(id),
      }
    );
  }

  public deleteProdotto(id: number) {
    this._prodottiService.delete(id).subscribe((_) => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneProdottoAvvenuta'));
      this.tableProdotti.loadItems();
    });
  }

  public isDeleteDisabled(prodotto: IProdotto) {
    if (prodotto.inizioValidita < new Date() && prodotto.fineValidita === null) return false;

    return prodotto.inizioValidita > new Date() || (prodotto.fineValidita !== undefined && prodotto.fineValidita < new Date());
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<ProdottoFormItem>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }

    if (onFormItemChangeEvent.ctrlName == 'associazioniProdottoCentro') {
      var stabilimenti = this.newEditSidebarForm.formItem?.associazioniProdottoCentro.map(y => y.centro?.id);
      this.tempUtenteProdotto = this.utenteProdotto.filter(x => stabilimenti?.includes(x.utente?.idCorp01004Centro));
    }
  }
}
