<app-dropdown-lingue class="login-dropdown-lingue"></app-dropdown-lingue>

<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center">
      <div class="login-box-shadow">
          <div class="w-full surface-card py-8 px-5 sm:px-8">
              <img class="img-logo-login mb-4" src="assets/layout/images/themes/logo-blu.png" />              
                <form *ngIf="!showAuthSintesiUk" class="mt-3" (ngSubmit)="onNextLoginStep()">
                  <div class="flex flex-column mb-5">
                    <label for="username" class="w-full text-xl font-medium mb-2">{{ 'UsernameLabel' | transloco }}</label>
                    <input
                      id="username"
                      type="text"
                      class="w-full md:w-30rem mb-3"
                      autofocus
                      placeholder="{{ 'UsernameLabel' | transloco }}"
                      pInputText
                      [(ngModel)]="username"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <button
                      pButton
                      type="submit"
                      title="Submit"
                      pRipple
                      label="{{ 'NextButton' | transloco }}"
                      class="button_login w-full text-xl mb-3"
                      [disabled]="!username"
                    ></button>
                  </div>
                </form>
                <cfm-auth-sintesi-uk *ngIf="showAuthSintesiUk" [username]="username"></cfm-auth-sintesi-uk>
          </div>
      </div>
  </div>
</div>

<app-modal-version class="login-versione"></app-modal-version>
