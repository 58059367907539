import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';

interface Lingua {
  name: string;
  code: string;
  translocoLangCode: string;
}

@Component({
  selector: 'app-dropdown-lingue',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ],
  templateUrl: './cfm-dropdown-lingue.component.html',
  styleUrl: './cfm-dropdown-lingue.component.scss',
})
export class DropdownLingueComponent implements OnInit {
  public lingueDisponibili: Lingua[] = [];
  public linguaSelezionata: Lingua | null = null;
  public hoveredLanguage: Lingua | null = null;

  constructor(
    private _translocoService: TranslocoService,
    private _appComponent: AppComponent
  ) {}

  ngOnInit() {
    // Nel caso in cui l'utente è già autenticato attende il caricamento delle lingue disponibili da API.
    this._appComponent.avaiableLangsLoaded$.subscribe(() => {
      this.setLingueDisponibili();
      this.setLinguaSelezionata();
    });

    // Nel caso in cui l'utente è arrivato tramite login le lingue disponibili sono già state recuperate da API.
    this.setLingueDisponibili();
    this.setLinguaSelezionata();
  }

  public setLingueDisponibili() {
    const availableLangs = this._translocoService.getAvailableLangs();
  
    this.lingueDisponibili = availableLangs.map(lang => {
      let langName = typeof lang === 'string' ? lang : lang.label;

      let langNameDisplay = "";
      if (langName === 'en') { langNameDisplay = 'English'; }
      else if (langName === 'es') langNameDisplay = 'Español';
      else if (langName === 'it') langNameDisplay = 'Italiano';

      return <Lingua> {
        name: langNameDisplay,
        code: langName === 'en' ? 'gb' : langName.toLowerCase(),
        translocoLangCode: langName.toLowerCase()
      };
    });
  }

  public setLinguaSelezionata() {
    const activeLang = this._translocoService.getActiveLang();
    this.linguaSelezionata = this.lingueDisponibili.find((lingua) => lingua.translocoLangCode === activeLang) || null;
  }

  public onChangeLinguaSelezionata(lingua: Lingua) {
    this._translocoService.setActiveLang(lingua.translocoLangCode);
  }

  public showLanguageName(language: Lingua) {
    this.hoveredLanguage = language;
  }

  public hideLanguageName() {
    this.hoveredLanguage = null;
  }
}