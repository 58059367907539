<p-toast></p-toast>
<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div style='border:1px solid #00000033' class="border-1 border-secondary border-circle inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8" [ngClass]="{'bg-orange-500':message.icon==='pi pi-exclamation-triangle'}">
                <i [ngClass]="{'mb-2': message.icon === 'pi pi-exclamation-triangle'}" [class]="message.icon + ' text-7xl'"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-3">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button pButton [label]="message.acceptLabel" [ngClass]="[message.acceptIcon, message.acceptButtonStyleClass]" (click)="cd.accept()"></button>
                <button pButton [label]="message.rejectLabel" [ngClass]="[message.rejectIcon, message.rejectButtonStyleClass]" (click)="cd.reject()"></button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>
<router-outlet></router-outlet>
<app-spinner-loading></app-spinner-loading>