import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { environment } from "../../environments/environment";
import { TableLazyLoadEvent } from "primeng/table";

export enum CountType {
    No = 0,
    Yes = 1
}

export interface IFindResult<T> {
  data: T[],
  count: number
}

export class SortInfo extends Map<string, 'ASC' | 'DESC'> {

  constructor(event: TableLazyLoadEvent) {
    super();

    if (event?.sortField && event.sortOrder) {
      const keys = Array.isArray(event.sortField) ? event.sortField : [event.sortField];
      for (const key of keys) {
        this.set(key, event.sortOrder == 1 ? 'ASC' : 'DESC');
      }
    }
  }

  public getQueryParamValues(): string[] {
    const queryParamValues = [];
    for (const key of this.keys()) {
      queryParamValues.push(`${key.toString()}:${this.get(key)}`);
    }
    return queryParamValues;
  }
}

export class BaseService<T> {
  protected constructor(protected serviceUrl: string, protected countType: CountType, protected _httpClient: HttpClient) {}

  public get(id: number): Observable<T> {
    return this._httpClient.get<T>(
      `${environment.baseApi}/${this.serviceUrl}/${id}`
    );
  }

  public find(filter?: Partial<T>, pageNumber?: number, pageSize?: number, orderBy?: SortInfo, relatedEntities?: Array<string>): Observable<IFindResult<T>> {
    let params = new HttpParams().set('countType', this.countType);

    if (filter !== undefined) {
        for (const filterName of Object.getOwnPropertyNames(filter)) {
            // Determinazione valore del filtro
            let value = (<any> filter)[filterName];
            if (value instanceof Date) value = value.toISOString();

            // Applicazione del filtro ai query parameter
            params = params.set(filterName, value);
        }
    }

    if (relatedEntities != undefined) {
      for (const index in relatedEntities) {
          params = params.append('nav', relatedEntities[index].toString());
      }
    }

    if (orderBy !== undefined) {
      for (const orderByQueryParamValue of orderBy.getQueryParamValues()) {
        params = params.set('orderBy', orderByQueryParamValue);
      }
    }

    if (pageNumber && pageSize) {
        params = params.set('limit', pageSize);
        params = params.set('offset', (pageNumber - 1) * pageSize);
    }

    return this._httpClient
      .get<T[]>(`${environment.baseApi}/${this.serviceUrl}`, {  params, observe: 'response' })
      .pipe(map(res => <IFindResult<T>> {
        data: res.body ?? [],
        count: res.headers.has('X-Total-Count') ? parseInt(res.headers.get('X-Total-Count')!) : res.body?.length ?? 0
      }));
  }

	public findAll(relatedEntities?: Array<keyof T>): Observable<IFindResult<T>> {
    return this.find(undefined, undefined, undefined, undefined, relatedEntities as Array<string>);
  }
  
  public insert(newItem: T): Observable<T> {
    return this._httpClient.post<T>(
      `${environment.baseApi}/${this.serviceUrl}`,
      newItem
    );
  }

  public update(id: number, newItem: T): Observable<void> {
    return this._httpClient.put<void>(
      `${environment.baseApi}/${this.serviceUrl}/${id}`,
      newItem
      );
  }

  public delete(id: number): Observable<void> {
    return this._httpClient.delete<void>(
      `${environment.baseApi}/${this.serviceUrl}/${id}`
    );
  }
}
