import { Injectable } from '@angular/core';
import { IEtichettaTraduzione } from './etichetta-traduzione.service';
import { BaseService, CountType } from './base.service';
import { HttpClient } from '@angular/common/http';

export interface IProfilo {
  id: number;
  idCorp03005EtichettaTraduzione: number;
  denominazione: string;

  etichettaTraduzione?: IEtichettaTraduzione;
}

@Injectable({
  providedIn: 'root'
})
export class ProfiloService extends BaseService<IProfilo> {

  constructor(_httpClient: HttpClient) {
    super('service-db-api/profili', CountType.Yes, _httpClient);
  }
}
