<div class="dashboard-card-padding" (click)="onclick()">
  <div class="card mb-0" [style.backgroundColor]="backgroundColor">
      <div class="card-header flex justify-content-between mb-3" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
          <div class="dashboard-card-icon flex align-items-center justify-content-center border-round">
              <img class="icon" src="{{ iconImageUrl }}" />
          </div>
      </div>
      <div>
          <span class="block titolo-card text-500 ">{{ title }}</span>
      </div>
      <div class="text-900 numero-card text-xl">{{ value }}</div>
  </div>
</div>
