<cfm-page-header title="{{ 'ConfigurazioneModelli' | transloco }}"></cfm-page-header>
<cfm-table
  #tableModelli
  headerIcon="fa-solid fa-mobile-retro"
  title="{{ 'ListaModelli' | transloco }}"
  [items]="modelli"
  [totalItemsCount]="totalCount"
  (onLoadItems)="loadModelli($event)"
  (onAdd)="onClickAdd()"
>
  <cfm-table-col header="Id" fieldName="id" [orderable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'Produttore' | transloco }}" fieldName="produttore.sigla" filterBy="idCorp06004ProduttoreDeviceMobile" [orderable]="true" [filterable]="true" [filterOptions]="produttori" filterOptionLabel="sigla" filterOptionValue="id">
    <ng-template #content let-item="item">
      {{item.produttore.sigla}}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'Sigla' | transloco }}" fieldName="sigla" [orderable]="true" [filterable]="true" filterType="text" [orderable]="true" [filterable]="true"></cfm-table-col>
  <cfm-table-col header="{{ 'InizioValidità' | transloco }}" fieldName="inizioValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-inizioValidita>
      {{ inizioValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="{{ 'FineValidità' | transloco }}" fieldName="fineValidita" [orderable]="true" [filterable]="true" filterType="date" filterDateFormat="dd/mm/yy">
    <ng-template #content let-fineValidita>
      {{ fineValidita | date : "dd/MM/yyyy" }}
    </ng-template>
  </cfm-table-col>
  <cfm-table-col header="" fieldName="">
    <ng-template #content let-item="item">
        <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
        <p-button icon="pi pi-ellipsis-v" (click)="openMenu(menu, $event, item)"
                  [style]="{'background-color': 'transparent', 'color':'black', 'border-color':'transparent'}"></p-button>
    </ng-template>
</cfm-table-col>
</cfm-table>

<cfm-sidebar-form
    #newEditSidebarForm
    [formTitle]="formTitle | transloco"
    [validators]="formValidators"
    (onSave)="onFormSave($event)"
    (onChange)="onFormChange($event)"
>
  <cfm-sidebar-form-item
    label="{{ 'Produttore' | transloco }}"
    key="idCorp06004ProduttoreDeviceMobile"
    type="dropdown"
    dropdownOptionLabel="sigla"
    dropdownOptionValue="id"
    [dropdownOptions]="produttori"
    [colWidth]="12"
/>
  <cfm-sidebar-form-item
    label="{{ 'Sigla' | transloco }}"
    key="sigla"
    type="text"
    [colWidth]="12"
  />
  <cfm-sidebar-form-item
    label="{{ 'InizioValidità' | transloco }}"
    key="inizioValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [maxDate]="inizioValiditaMaxDate"
/>
<cfm-sidebar-form-item
    label="{{ 'FineValidità' | transloco }}"
    key="fineValidita"
    type="date"
    [dateFormat]="getDateFormat"
    [colWidth]="6"
    [minDate]="fineValiditaMinDate"
    [disabled]="isFineValiditaDisabled"
/>
</cfm-sidebar-form>
