import { Component, ViewChild } from '@angular/core';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../../components/cfm-table/cfm-table.component';
import { DatePipe } from '@angular/common';
import { CfmPageHeaderComponent } from '../../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableColComponent } from '../../../../components/cfm-table-col/cfm-table-col.component';
import { IProduttore, ProduttoriService } from '../../../../services/produttori.service';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CfmSidebarFormItemComponent } from '../../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { CfmMessageService } from '../../../../services/cfm-message.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-produttori',
  standalone: true,
  imports: [
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
		CfmSidebarFormComponent,
    CfmSidebarFormItemComponent,
		ButtonModule,
    TranslocoModule,
    MenuModule
  ],
  templateUrl: './produttori.component.html',
  styleUrl: './produttori.component.scss'
})
export class ProduttoriComponent {
	@ViewChild('tableProduttori') public tableProduttori!: CfmTableComponent<IProduttore>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<IProduttore>;

  public produttori: IProduttore[] = [];
  public items: MenuItem[] = [];
  public totalCount: number = 0;
	public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof IProduttore, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovoProduttore';
    else return 'ModificaProduttore';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }
  
  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  constructor(private _translocoService: TranslocoService,private _produttoriService: ProduttoriService, private _cfmMessageService: CfmMessageService) {

    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(100)]);
    this.formValidators.set('inizioValidita', [Validators.required]);
	}

  openMenu(menu: Menu, event: MouseEvent, produttore: IProduttore) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(produttore)               
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(produttore),
                  command: () => this.onClickDelete(produttore.id)
                }
            ]
        }
    ];
  }

  public loadProduttori(event: OnLoadItemsEvent<IProduttore>): void {
    this._produttoriService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy).subscribe(result => {
      this.produttori = result.data;
      this.totalCount = result.count;
    });
  }

	public onClickAdd(): void {
    this.formMode = 'add';
    this.newEditSidebarForm.show();
  }

  public onClickEdit(produttore: IProduttore): void {
    this.formMode = 'edit';
    this.newEditSidebarForm.showOnEditMode(produttore);
  }

  public onFormSave(produttore: IProduttore): void {
    if (this.formMode == null) return;
    this.formMode == 'add' ? this.insertProduttore(produttore) : this.updateProduttore(produttore);
  }

  private insertProduttore(produttore: IProduttore): void {
    this._produttoriService.insert(produttore).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableProduttori.loadItems();
    });
  }

  private updateProduttore(produttore: IProduttore): void {
    this._produttoriService.update(produttore.id, produttore).subscribe(() => {
      this.newEditSidebarForm.hide();
      this.tableProduttori.loadItems();
    });
  }

	public onClickDelete(id: number){
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneProduttore'),
        accept: () => this.deleteProduttore(id)
      },
    );
  }

  public deleteProduttore(id: number) {
    this._produttoriService.delete(id).subscribe(_ => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneProduttoreCompletata'));
      this.tableProduttori.loadItems();
    });
  }

	public isDeleteDisabled(produttore: IProduttore) {
    if (produttore.inizioValidita < new Date() && produttore.fineValidita === null) return false;
    
    return produttore.inizioValidita > new Date() || (produttore.fineValidita !== undefined && produttore.fineValidita < new Date());
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<IProduttore>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
