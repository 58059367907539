import { CommonModule, DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { INazione, NazioniService } from '../../../services/nazioni.service';
import { CfmPageHeaderComponent } from '../../../components/cfm-page-header/cfm-page-header.component';
import { CfmTableComponent, OnLoadItemsEvent } from '../../../components/cfm-table/cfm-table.component';
import { CfmTableColComponent } from '../../../components/cfm-table-col/cfm-table-col.component';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators} from '@angular/forms';
import { CfmSidebarFormComponent, OnFormItemChangeEvent } from '../../../components/cfm-sidebar-form/cfm-sidebar-form.component';
import { CfmSidebarFormItemComponent } from '../../../components/cfm-sidebar-form-item/cfm-sidebar-form-item.component';
import { ButtonModule } from 'primeng/button';
import { CfmMessageService } from '../../../services/cfm-message.service';
import { DropdownModule } from 'primeng/dropdown';
import { AziendeService, IAzienda } from '../../../services/aziende.service';
import { ILingua, LingueService } from '../../../services/lingue.service';
import { IImmagine } from '../../../services/immagini.service';
import SistemiInformativiService, { ISistemaInformativo } from '../../../services/sistemi-informativi.service';
import { Observable, forkJoin } from 'rxjs';
import { IFindResult } from '../../../services/base.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Menu, MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-nazioni',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CfmPageHeaderComponent,
    CfmTableComponent,
    CfmTableColComponent,
    DatePipe,
    CfmSidebarFormComponent,
    TranslocoModule,
    CfmSidebarFormItemComponent,
    ButtonModule,
    FormsModule,
    DropdownModule,
    CommonModule,
    MenuModule
  ],
  templateUrl: './nazioni.component.html',
  styleUrl: './nazioni.component.scss',
})
export class NazioniComponent {

  @ViewChild('tableNazioni') tableNazioni!: CfmTableComponent<INazione>;
  @ViewChild('newEditSidebarForm') public newEditSidebarForm!: CfmSidebarFormComponent<INazione>;

  public totalCount: number = 0;
  public formMode: 'add' | 'edit' | null = null;
  public formValidators: Map<keyof INazione, Array<(control: AbstractControl<any, any>) => ValidationErrors | null>> = new Map();

  // Proprietà utili alla form di inserimento/modifica azienda
  public aziende: IAzienda[] = [];
  public nazioni: INazione[] = [];
  public nazioneSelezionata!: INazione;
  public sistemaInformativoGestionali: ISistemaInformativo[] = [];
  public lingue: ILingua[] = [];
  public imageData!: IImmagine;
  public items: MenuItem[] = [];

  constructor(private _translocoService: TranslocoService,private _nazioniService: NazioniService, private _cfmMessageService:CfmMessageService, private _aziendeService: AziendeService, private _sistemiInformativiService: SistemiInformativiService, private _linguaService: LingueService) {
    this.formValidators.set('idCorp01002Azienda', [Validators.required]);
    this.formValidators.set('idCorp01005SistemaInformativoGestionale', [Validators.required]);
    this.formValidators.set('idCorp03001Lingua', [Validators.required]);
    this.formValidators.set('codiceNazioneLotto', [Validators.required, Validators.maxLength(1)]);
    this.formValidators.set('sigla', [Validators.required, Validators.maxLength(50)]);
    this.formValidators.set('lingua', [Validators.required]);
    this.formValidators.set('logo', [Validators.required]);
    this.formValidators.set('inizioValidita', [Validators.required]);

    this.loadAziende().subscribe(result => this.aziende = result.data);
    this.loadSistemaInformativi().subscribe(result => this.sistemaInformativoGestionali = result.data);
    this.loadLingue().subscribe(result => this.lingue = result.data);
  }

  public get formTitle(): string {
    if (this.formMode == 'add') return 'NuovaNazione';
    else return 'ModificaNazione';
  }

  public get getDateFormat(): string {
    return 'dd/mm/yy';
  }

  public get inizioValiditaMaxDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.fineValidita ?? null;
  }

  public get fineValiditaMinDate(): Date | null {
    return this.newEditSidebarForm?.formItem?.inizioValidita ?? null;
  }

  public get fineValiditaMaxDate(): Date | null {
    const idAzienda = this.newEditSidebarForm?.formItem?.idCorp01002Azienda;
    if (idAzienda == null) return null;
    return this.aziende.find(a => a.id === idAzienda)?.fineValidita ?? null;
  }

  public get inizioValiditaMinDate(): Date | null {
    const idAzienda = this.newEditSidebarForm?.formItem?.idCorp01002Azienda;
    if (idAzienda == null) return null;
    return this.aziende.find(a => a.id === idAzienda)?.inizioValidita ?? null;
  }

  public get isFineValiditaDisabled(): boolean {
    return !this.newEditSidebarForm?.formItem?.inizioValidita;
  }

  public openMenu(menu: Menu, event: MouseEvent, nazione: INazione) {
    menu.toggle(event);
 
    this.items = [
      {
        label: this._translocoService.translate<string>('Azioni'),
        items: [
                {
                  label: this._translocoService.translate<string>('Modifica'),
                  icon: 'pi pi-user-edit',
                  iconStyle: {'color': '#E0A800'},
                  command: () => this.onClickEdit(nazione)                 
                },
                {
                  label: this._translocoService.translate<string>('Cancella'),
                  icon: 'pi pi-trash',
                  iconStyle: {'color': 'red'},
                  disabled: this.isDeleteDisabled(nazione),
                  command: () => this.onClickDelete(nazione.id)
                }
            ]
        }
    ];
  }

  public loadAziende(): Observable<IFindResult<IAzienda>> {
    return this._aziendeService.find();
  }

  
  public loadSistemaInformativi(): Observable<IFindResult<ISistemaInformativo>> {
    return this._sistemiInformativiService.find();
  }
  
  public loadLingue(): Observable<IFindResult<ILingua>> {
    return this._linguaService.find();
  }
  
  public loadNazioni(event: OnLoadItemsEvent<INazione>): void {
    const relatedEntities: Array<keyof INazione> = ['logo', 'sistemaInformativoGestionale', 'azienda', 'lingua'];
    this._nazioniService.find(event.filter, event.pageNumber, event.pageSize, event.orderBy, relatedEntities).subscribe(result => {
        this.nazioni = result.data;
        this.totalCount = result.count;
    });
  }
  
  public onClickAdd(): void {
    this.formMode = 'add';
    forkJoin([
        this.loadAziende(),
        this.loadSistemaInformativi(),
        this.loadLingue()
    ])
    .subscribe(([rAziende, rSistemiInformativi, rLingue]) => {
        this.aziende = rAziende.data;
        this.sistemaInformativoGestionali = rSistemiInformativi.data;
        this.lingue = rLingue.data;
        this.newEditSidebarForm.show();
    });
  }

  public onClickEdit(nazione: INazione): void {
    //Per recuperare le dropdown
    forkJoin([
      this.loadAziende(),
      this.loadSistemaInformativi(),
      this.loadLingue()
    ])
    .subscribe(([rAziende, rSistemiInformativi, rLingue]) => {
      this.aziende = rAziende.data;
      this.sistemaInformativoGestionali = rSistemiInformativi.data;
      this.lingue = rLingue.data;

      nazione.logo = { nomeFileImmagine: nazione.logo.nomeFileImmagine, binarioImmagine: nazione.logo.binarioImmagine, binarioImmagineB64: nazione.logo.binarioImmagineB64 };
      this.formMode = 'edit';
      this.newEditSidebarForm.showOnEditMode(nazione);
    });
  }

  public onFormSave(nazione: INazione) {
    if (this.formMode == null) return;
    this.formMode === 'add' ? this.insertNazione(nazione) : this.updateNazione(nazione);
  }

  private insertNazione(nazione: INazione): void {
    nazione.logo = this.imageData;
    this._nazioniService.insert(nazione).subscribe(() => {
        this.newEditSidebarForm.hide();
        this.tableNazioni.loadItems();
    });
  }

  private updateNazione(nazione: INazione): void {
    if(nazione.logo.binarioImmagine == null) nazione.logo = this.imageData;
    this._nazioniService.update(nazione.id, nazione).subscribe(() => {
        this.newEditSidebarForm.hide();
        this.tableNazioni.loadItems();
    });
  }

  public onClickDelete(id: number) {
    this._cfmMessageService.showConfirmDialog(
      {
        header: this._translocoService.translate<string>('Attenzione'),
        message: this._translocoService.translate<string>('ConfermaEliminazioneNazione'),
        accept: () => this.deleteNazione(id),
      }
    );
  }

  public deleteNazione(id: number) {
    this._nazioniService.delete(id).subscribe((_) => {
      this._cfmMessageService.showSuccessMessage(this._translocoService.translate<string>('CancellazioneNazioneAvvenuta'));
      this.tableNazioni.loadItems();
    });
  }

  public isDeleteDisabled(nazione: INazione) {
    if (nazione.inizioValidita < new Date() && nazione.fineValidita === null) return false;
    
    return nazione.inizioValidita > new Date() || (nazione.fineValidita !== undefined && nazione.fineValidita < new Date());
  }

  public onImageUploaded(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageData = {
        nomeFileImmagine: file.name,
        binarioImmagine: '',
        binarioImmagineB64: reader.result ? reader.result.toString() : ''
      };
    };
    reader.readAsDataURL(file);
  }

  public onFormChange(onFormItemChangeEvent: OnFormItemChangeEvent<INazione>): void {
    if (onFormItemChangeEvent.ctrlName == 'inizioValidita' && !onFormItemChangeEvent.newValue) {
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
    else if (onFormItemChangeEvent.ctrlName == 'idCorp01002Azienda' && onFormItemChangeEvent.newValue !== undefined) {
      this.newEditSidebarForm.setFormItemValue('inizioValidita', undefined);
      this.newEditSidebarForm.setFormItemValue('fineValidita', undefined);
    }
  }
}
