import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export type FormItemType = 'text' | 'date'| 'dropdown' | 'upload' | 'number' | 'multiSelect';

@Component({
  selector: 'cfm-sidebar-form-item',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule, 
  ],
  templateUrl: './cfm-sidebar-form-item.component.html',
  styleUrl: './cfm-sidebar-form-item.component.scss'
})
export class CfmSidebarFormItemComponent<T> implements OnChanges {
  @ContentChild("content") contentTemplateRef!: TemplateRef<any>;

  @Input({ required: true }) public label!: string;
  @Input({ required: true }) public key!: keyof T;
  @Input({ required: true }) public type!: 'text' | 'date'| 'dropdown' | 'upload' | 'number' | 'multiSelect';
  @Input() public colWidth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 = 12;
  @Input() public dateFormat!: string;
  @Input() public defaultValue: any;
  @Input() public dropdownOptions!: Array<any>;
  @Input() public dropdownOptionLabel!: string; 
  @Input() public dropdownOptionValue: string | undefined; 
  @Input() public minDate: Date | null = null; 
  @Input() public maxDate: Date | null = null; 
  @Input() public disabled: boolean = false;
  
  @Output() public onDisabledChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}
  
  /**
   * Questo metodo viene chiamato ogni volta che le proprietà di input del componente cambiano.
   * Controlla se la proprietà 'disabled' è cambiata e emette un evento con il nuovo valore.
   * Inoltre, contrassegna il componente per il controllo per assicurarsi che i cambiamenti siano riflessi nella vista.
   *
   * @param changes - Un oggetto che contiene l'oggetto SimpleChanges, che contiene i valori precedenti e correnti delle proprietà di input.
   */  
  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('disabled')) {
      this.onDisabledChange.emit(changes['disabled'].currentValue);
      this.cdr.markForCheck(); // Forza il rilevamento dei cambiamenti
    }
  }

  public get widthClass(): string {
    return 'col-' + this.colWidth;
  }

  public get formControlName(): string {
    return this.key.toString();
  }
}
