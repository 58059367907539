import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, CountType } from './base.service';
import { IAzienda } from './aziende.service';
import { INazione } from './nazioni.service';
import { ICentro } from './centri.service';
import { IUtente } from './utenti.service';

export interface IProdotto {
  id: number;
  denominazione: string;
  codice: string;
  inizioValidita: Date;
  fineValidita: Date | undefined;
  associazioniProdotto: IAssociazioneProdotto[];
  idCorp1002Azienda?: number | null;
  idCorp1003Nazione?: number | null;
  idCorp1004Centro?: number | null;

  prodottoUtente?: IUtenteProdotto[];
}

export interface IUtenteProdotto {
  id: number;
  idCorp04002Prodotto: number;
  idCorp05001Utente: number;

  utente?: IUtente;
}

export interface IAssociazioneProdotto {
  id?: number;
  idCorp1002Azienda?: number | null ;
  idCorp1003Nazione?: number;
  idCorp1004Centro?: number;
  idCorp4002Prodotto?: number;

  prodotto?:IProdotto;
  azienda?: IAzienda | null;
  nazione?: INazione | null;
  centro?: ICentro | null;
}

@Injectable({
  providedIn: 'root'
})
export class ProdottiService extends BaseService<IProdotto> {
  constructor(_httpClient: HttpClient) {
    super('service-db-api/prodotti', CountType.Yes, _httpClient);
  }
}
