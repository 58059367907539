import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { CfmMessageService } from '../services/cfm-message.service';

export const permissionGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cfmMessageService = inject(CfmMessageService);
  const translocoService = inject(TranslocoService);

  const routePath = route.routeConfig?.path;
  const hasPermission = routePath ? checkPermission(routePath) : false;

  if (hasPermission) {
    return hasPermission;
  } else {
    cfmMessageService.showSuccessMessage(translocoService.translate<string>('PermessiInsufficienti'));
    return router.parseUrl('/dashboard');
  }
};

const checkPermission = (routePath: string): boolean => {
  return true;
};
